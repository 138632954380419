import config from '../../components/config/config'
import {axiosFunc, AppenData ,EncryptData, Decryptdata} from '../common'



var token;

export const GetUserCookieToken = async() => {
    token = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
      }, '');
      return true
}


export const userRegister    =   async   (data)  =>  {
    console.log("userRegister",data);

  try{  let  senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/user/create`,
        data    :   {data: EncryptData(data)},
        headers :{
            "content-type" : 'application/json'
        }  
    }
    console.log("erererer",senddata)
    let Resp = await axiosFunc(senddata)
 
    return Resp
}
catch(err){
    console.log('userRegister_err',err)
}
}

export const getCmsContent  = async(data)=>{
    try{
        let  senddata    =   {
            method  :   'GET',
            url     :   `${config.BACK_URL}/user/getcms`,  
            params : data
        }
        console.log("senddata",senddata)

        let Resp    =   await axiosFunc(senddata)
        console.log("responser",Resp)
        return Resp
    }
    catch(err){console.log("getCmsContent_err",err)}

}

export const getFaq  = async()=>{
    
    try{

        let  senddata    =   {
            method  :   'GET',
            url     :   `${config.BACK_URL}/user/getfaq`,  
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp
    }
    catch(err){console.log("getFaq_err",err)}
  }

  export const getSocialData  = async()=>{
    try{
        let  senddata    =   {
            method  :   'GET',
            url     :   `${config.BACK_URL}/user/getSocialLinksfront`,  
        }
        let Resp    =   await axiosFunc(senddata)
        let response = Decryptdata(Resp)
        return response
    }
    catch(err){console.log("getSocialData_err",err)}
  }

  export const getSettings  = async()=>{
    try{
        let  senddata    =   {
            method  :   'GET',
            url     :   `${config.BACK_URL}/user/getsettings`,  
        }
        let Resp    =   await axiosFunc(senddata)
        let response = Decryptdata(Resp)
        return response
    }
    catch(err){console.log("getSocialData_err",err)}
}
  

  export const imgageupload=async(data)=>{
 try { 
    let sendData = await AppenData(data)
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/user/imagefileupload`,
        data    :   sendData[0],
        headers :{
            "content-type" : 'multipart/form-data'
            
        } 
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp
}
catch(err){
    console.log("imgageupload_err",err);
}
}

export const nftNameValidation   =   async   (data)  =>  {
   try{ let  senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/user/ValidateTokenName`,
        data    :   {data : EncryptData(data)},
        headers :{
            "content-type" : 'application/json'
        } 
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp}
    catch(err){
        console.log("nftNameValidation_err",err);
    }
}


export const getpooldata= async()=>{
   try{ let  senddata={
        method  :   'post',
        url     :   `${config.ADMIN_URL}/addeditdelete`,
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp}
    catch(err){
        console.log('getpooldata_err',err);
    }
}

export const roadmaplist = async () => {
    try{
        let  senddata    =   {
            method  :   'GET',
            url     :   `${config.BACK_URL}/user/roadmaplist`,  
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp
    }
    catch(err){console.log("getSocialData_err",err)}
}

export const getaboutuser = async () => {
    try{
        let  senddata    =   {
            method  :   'GET',
            url     :   `${config.BACK_URL}/user/getaboutuser`,  
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp
    }
    catch(err){console.log("getaboutuser_err",err)}
}

