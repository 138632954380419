import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import coin from '../assets/images/coin.png';
import Storecard from '../components/storecard';

import top_line from '../assets/images/header_border.png';

import rightborder from '../assets/images/right1.png';
import leftborder from '../assets/images/right2.png';

import bottom_line from '../assets/images/footer_border.png';
import inner_line from '../assets/images/inner_line.png';
import Mintingmodal from '../components/modal/mintingmodal';
import { useSelector } from 'react-redux';

//axios
import { Token_List_Func } from '../actions/axios/nftaxios';
import Loader from '../layout/Loader';
import HeaderOne from '../layout/HeaderOne';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from '../actions/common';


export default function Store() {
  const [mintingModal, setMintingModal] = useState(false);
  const [buydetail, setBuydetail] = useState({});
  const [TabName, SetTabName] = useState("equipments");
  const [loadingstate, setLoadingstate] = useState(true)
  const { accountAddress } = useSelector(state => state.LoginReducer.AccountDetails)
  const { AdminAddress,balance} = useSelector((state) => state.LoginReducer);
  const [Tokens, SetTokens] = useState([]);
  const { isStake ,Settings } = useSelector((state) => state.LoginReducer);
const [loadd,setLoad]=useState(true)
  const [page, setPage] = useState(1)
  const navigate = useNavigate()

  useEffect(()=>{
    if(!isEmpty(Settings) && !Settings?.walletsection){
      navigate('/')
    }
  },[Settings])

  const tokenonclick = (e) => {
    if(accountAddress){
    setBuydetail(e); setMintingModal(true)
    }
    else{
      toast.error("Please connect Wallet")
    }
  }

  useEffect(() => {
    Explore(1)
  }, [isStake])

  const Explore = async (data) => {
    let pages = data? data : page
    let  SendDATA = {
      TabName: TabName,
      limit: 12,
      page: pages,
      from: 'store',
      status : "sale"
    };
    let Resp = await Token_List_Func(SendDATA);
    if (Resp?.data) {
      SetTokens(SendDATA?.page == 1 ? Resp?.data : [...Tokens,...Resp?.data])
      setPage(pages+1)
    }
    if(Resp?.data?.length==0){
      setLoad(false)
    }
    setLoadingstate(false)
  };


  const load=async()=>{
    Explore(page)
  }



  return (
    <div className='inner_pages'>
      <HeaderOne />
      <div className='store_pages'>
        <div className='inner_sec'>

          <div className='container inner_container custody_container'>


            <div className='top_sec'>
              <div className='row'>
                <div className='text-center mb-4'>
                  <p className='page_title imfell_font'>Store</p>
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
                </div>
                <div className='desc_sec'>
                  <p className='page_desc averia_font'>Use Savage Tokens to purchase upgrades and items for your Savage Warriors.</p>
                  <div className='coin_sec'>
                    <p className='balance_coin'><img src={coin} alt='coin' className='img-fluid' width={25} /> x {balance}</p>
                  </div>
                </div>
              </div>

            </div>
            
            <div className='store_card_sec'>
              {Tokens.length>0 &&
              <>
            <img src={rightborder} className='img-fluid rightborder goldborder' alt='store_line' />
            <img src={leftborder} className='img-fluid leftborder goldborder' alt='store_line' /> 
            <img src={rightborder} className='img-fluid brightborder goldborder' alt='store_line' /> 
            <img src={leftborder} className='img-fluid bleftborder goldborder' alt='store_line' /></> }
              <div className='row'>
                {
                  Tokens.map((e) => {
                    if (AdminAddress != accountAddress) {
                      return (
                        <div className='col-xl-3 col-md-6 mb-4' onClick={() => tokenonclick(e)}>
                          <Storecard
                            store_img={e.store_img} desc={e.desc} price={e.price} title={e.title} product={e}  />
                        </div>
                      )
                    }
                    else {
                      return (
                        <div className='col-xl-3 col-md-6 mb-4' >
                          <Storecard
                            store_img={e.store_img} desc={e.desc} price={e.price} title={e.title} product={e} />
                        </div>
                      )
                    }


                  })
                }
               {loadd && <div className='loadmore_btn_sec text-center'>
          <button className='btn loadmore_btn' alt="loadmore" onClick={() => load()} >Load More</button>
          </div>}
               
          </div>


        </div>
      </div>



     
      {mintingModal && <Mintingmodal
        imgbuy={buydetail.store_img}
        show={mintingModal}
        onHide={() => setMintingModal(false)}
        product={buydetail}
        buymodal={true}
      />
      }

    </div>
    <Footer />
    </div>
    </div>
  )
}