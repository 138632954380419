import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import coin from '../assets/images/coin.png'
import QuestCard from '../components/questcard';
import useContractProviderHook from "../actions/contractProviderHook";
import { useSelector } from "react-redux";
import inner_line from '../assets/images/inner_line.png'
import HeaderOne from '../layout/HeaderOne';
import { getpooldata } from '../actions/axios/useaxios';
import config from '../components/config/config'
import { getstaked } from '../actions/axios/nftaxios';
import Nodata from '../components/nodata';
import { useLocation, useNavigate } from 'react-router-dom';
import { Decryptdata, EncryptData, isEmpty } from '../actions/common';


export default function Quest() {
  const { accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const navigate = useNavigate()
  const location = useLocation()
  const { balance,Settings } = useSelector((state) => state.LoginReducer)
  const [def, setDef] = useState('home')

  const [availmore, setAvailload] = useState(2)
  const [Avail, setAvail] = useState(true)

  const [activemore, setactiveload] = useState(2)
  const [active, setActive] = useState(true)
    
  const [compmore, setCompload] = useState(2)
  const [comp, setComp] = useState(true)

  const [Available, setAvailable] = useState()
  const [Active, setActived] = useState()
  const [complete, setComplete] = useState()
const [Tab,SetTabName]=useState()
const [page,setPage]=useState(1)

useEffect(()=>{
  if(!isEmpty(Settings) && !Settings?.walletsection){
    navigate('/')
  }
},[Settings])
  
  useEffect(() => {
    if(accountAddress){
     
      if (location?.state) {
       
        setDef(location?.state)
      }
      getactivecompleted()
    }
    getpools()
  }, [accountAddress])

  useEffect(()=>{
  
    setAvailload(2)
    setactiveload(2)
    setCompload(2)
    setComp(true)
    setActive(true)
  },[Tab])

  const getpools = async () => {
    let resp = await getpooldata()
    
    if (resp?.status) {
      resp.data =   resp.data?.map((val)=>{
        val.hash = Decryptdata(val.hash,'key')
        
        return val
      })
      
      setAvailable(resp?.data)
    }
  }


  const getactivecompleted = async (data) => {
    let  resp = await getstaked({
    page : page,
    limit : 12,
    add : accountAddress
    })
  
    if (resp?.status) {
      setActived( page == 1 ? resp.data.filter((item) => item.active == true) : [...Active,...resp.data.filter((item) => item.active == true)])
      setComplete(page == 1 ? resp.data.filter((item) => item.active == false)  : [...complete,...resp.data.filter((item) => item.active == false)])
      setPage(page+1)
    }
    if(resp?.data?.length == 0){
      switch(data) {
        case "active":
          setActive(false)
          break;
        case "comp":
          setComp(false)
          break;
        default:
          break;
      }

    }

  }


  const load = (data) => {
    if (data == 'avail') {
      if (Available?.length > availmore) {
        setAvailload(availmore + 2)
      }
      else {
        setAvail(false)
      }

    }
    if (data == 'active') {
      if (Active?.length > activemore) {
        setactiveload(activemore + 2)
      }
      else {
        setactiveload(activemore + 2)
        getactivecompleted(data)
      }

    }
    if (data == 'comp') {
      if (complete?.length > compmore) {
        setCompload(compmore + 2)
      }
      else {
        setCompload(compmore + 2)
        getactivecompleted(data)
      }

    }
  }

  return (
    <div className='inner_pages'>
      <HeaderOne />
      <div className='quest_pages'>
        <div className='inner_sec'>
          <div className='container inner_container'>
            <div className='top_sec'>
              <div className='row'>
                <div className='text-center mb-4'>
                  <p className='page_title imfell_font'>Quests</p>
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120}/>
                </div>
                <div className='desc_sec'>
                  <p className='page_desc averia_font'>Quests are a collector's opportunity to earn exclusive items and rewards for the NFTs</p>
                  <div className='coin_sec'>
                    <p className='balance_coin'><img src={coin} alt='coin' className='img-fluid' width={25} /> x {balance}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='tab_sec'>
              <Tabs
                defaultActiveKey={location?.state ? location?.state : 'home'}
                id="uncontrolled-tab-example"
                className="tabs_design mb-3"
                onSelect={(e) => SetTabName(e)}
              >
                <Tab eventKey="home" title="Available">
                  {Available?.length > 0 ?

                    <>
                      <div className='row'>
                        {Available?.slice(0, availmore).map((item) => (
                          <div className='col-xl-6 mb-4'>
                            <QuestCard
                              desc={item?.Description}
                              poolid={item?.poolid}
                              heading={item?.heading}
                              quest_img={`${config.IMG_URL}/pools/${item?.img}`}
                              category={item?.category}
                              requiretype ={item.requiretype}
                              days={item?.Lockperiod} nftscount={item?.Requirments} tokens={item?.Tokenqantity} type={"available"}
                              attribute ={item?.attributes}
                              hash = {item?.hash}
                              pooldata = {item?.pooldata}
                            />
                          </div>
                        ))}
                      </div>

                      {Avail ? <div className='loadmore_btn_sec'>
                        <button className='btn loadmore_btn' alt="loadmore" onClick={() => load('avail')} >Load More</button>
                      </div> : ""}
                    </>
                    :
                    <Nodata/>

                  }


                </Tab>
                <Tab eventKey="profile" title="Active">
                  {Active?.length > 0 ?
                    <>
                      <div className='row'>
                        {Active?.slice(0, activemore).map((item) => (
                          <div className='col-xl-6 mb-4'>
                            <QuestCard
                              desc={item?.pool?.Description}
                              poolid={item?.pool?.poolid}
                              heading={item?.pool?.heading}
                              quest_img={`${config.IMG_URL}/pools/${item?.pool?.img}`}
                              category={item?.pool?.category}
                              days={item?.pool?.Lockperiod} nftscount={item?.pool?.Requirments} tokens={item?.pool?.Tokenqantity} type={"active"}
                              endtime={item?.endtime}
                              requiretype ={item.requiretype}
                              stakeddata={item?.token}
                              pooldata = {item?.pooldata}
                            />
                          </div>
                        ))}
                      </div>
                      {active ? <div className='loadmore_btn_sec'>
                        <button className='btn loadmore_btn' alt="loadmore" onClick={() => load('active')} >Load More</button>
                      </div> : ""}
                    </>

                    :
                    <Nodata />

                  }


                </Tab>
                <Tab eventKey="contact" title="Completed" >
                  {complete?.length > 0 ?
                    <>
                      <div className='row'>
                        {complete?.slice(0, compmore).map((item) => (
                          <div className='col-xl-6 mb-4'>
                            
                            <QuestCard
                              desc={item?.pool?.Description}
                              poolid={item?.pool?.poolid}
                              heading={item?.pool?.heading}
                              quest_img={`${config.IMG_URL}/pools/${item?.pool?.img}`}
                              category={item?.pool?.category}
                              id={item?._id}
                              requiretype ={item.requiretype}
                              days={item?.pool?.Lockperiod} nftscount={item?.pool?.Requirments} tokens={item?.pool?.Tokenqantity} type={"completed"}
                              endtime={item?.endtime}
                              stakeddata={item.token}
                              pooldata = {item?.pooldata}
                            />
                          </div>
                        ))}
                      </div>
                      {comp ? <div className='loadmore_btn_sec'>
                        <button className='btn loadmore_btn' alt="loadmore" onClick={() => load('comp')} >Load More</button>
                      </div> : ""}
                    </>

                    :
                    <Nodata />

                  }
                </Tab>
              </Tabs>
            </div>
          </div>


        </div>
      </div>


      <Footer />

    </div>
  )
}
