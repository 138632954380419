import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import inner_line from '../../assets/images/inner_line.png';
import connect1 from '../../assets/images/connect1.png';
import connect2 from '../../assets/images/connect2.png';
import connect3 from '../../assets/images/connect3.png';
import { isEmpty } from '../../../src/actions/common';
import { connectWallet } from '../../hooks/usewallet';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { userRegister, GetUserCookieToken } from '../../actions/axios/useaxios'
import useContractProviderHook from "../../actions/contractProviderHook";


export default function Connectwalletmodal(props) {

  const dispatch = useDispatch();


  const { token } = useSelector(state => state.LoginReducer.User)



  const initialConnectWallet = async (type) => {

    const id = toast.loading(type + " Wallet Connecting...", { autoClose: 1000, closeButton: true, closeOnClick: true })


    let  accountDetails = await connectWallet(type)

    if (!isEmpty(accountDetails)) {
      let  resp = await setWalletAddress('InitialConnect', accountDetails.accountAddress, type)
      if (resp?.success == 'success') {
        toast.update(id, { render: resp.msg, type: "success", autoClose: 1000, isLoading: false, closeButton: true, closeOnClick: true })
        dispatch({
          type: "Account_Section",
          Account_Section: { AccountDetails: accountDetails }
        })
      }
      else {
        toast.update(id, { render: "Connect Wallet", type: 'error', autoClose: 1000, isLoading: false, })
      }

    }
    else toast.update(id, { render: "Try Again", type: 'error', autoClose: 1000, isLoading: false, closeButton: true, closeOnClick: true })

  }

  const setWalletAddress = async (type, walletAddress, walletType) => {
    if (walletAddress) {
      let  NewMethod = {
        Type: type,
        WalletAddress: walletAddress,
        WalletType: walletType,
      };
      let Resp = await userRegister(NewMethod);
      if (Resp?.success == 'success') {
        dispatch({
          type: 'Register_Section',
          Register_Section: {
            User: {
              payload: Resp.data,
              token: Resp.token ? Resp.token : token
            }
          }
        })
        document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
        GetUserCookieToken();
        return Resp
      }
      else return Resp
    }
    else return { success: 'error', msg: 'No Address Detected.. Check Your Wallet' }

  }



  return (
    <div >
      <Modal  {...props} centered size="md" className='revealmodal'>
        <div className='bg_pattern'></div>
        <div className='close_btn'>
          <p className='close_text' onClick={props.onHide}>X</p>
        </div>
        <Modal.Header >
          <Modal.Title><p>Connect your wallet</p>
            <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='btn_sec'>

            <Button className='modal_btn' variant="secondary" onClick={props.onHide}>
              <img src={connect1} alt="metamask" className='img-fluid' onClick={() => initialConnectWallet("MetaMask")} />
            </Button>
            <Button className='modal_btn' variant="primary" onClick={props.onHide}>
              <img src={connect2} alt="metamask" className='img-fluid' onClick={() => initialConnectWallet("Coinbase")} />
            </Button>
            <Button className='modal_btn' variant="primary" onClick={props.onHide}>
              <img src={connect3} alt="metamask" className='img-fluid' onClick={() => initialConnectWallet("WalletConnect")} />
            </Button>
          </div></Modal.Body>

      </Modal>
    </div>
  )
}
