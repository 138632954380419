import React from 'react';
import config from './config/config'
export default function Storecard({
  product
}
) {
  return (
    <div>


 <div className='card store_card_style'>
        <img 
        // src={`
        //   ${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/
        //   ${product?.CompressedFile}
        //   `} 
          src={`
            ${product?.CompressedFile}
            `} 
         className='img-fluid store_img' alt='store_img' />
        <div className='store_frame'></div>
        <p className='title'>
          {product?.NFTName?.length > 13 ? product?.NFTName?.substring(0, 10) + "..." : product?.NFTName}
          </p>
        <p className='desc'>
          {product?.NFTDescription}
          </p>
        <p className='price'>
            {product?.NFTPrice} {product?.CoinName} 
          </p>
      </div> 
    </div>
  )
}
