import config from '../../components/config/config'
import {axiosFunc} from '../common'
import axios from 'axios'


export const Currency    =   async   (data)  =>  {
try{
    let  senddata    =   {
        method  :   'get',
        url     :   `${config.ADMIN_URL}/currencylist`
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp
}
catch(err){
    console.log('Currency_err-->',err)
    return []
}
  
}
export const USDPRICE = async (data) =>{
    try{

        let  senddata ={
            'method': 'get',
            'url': `https://min-api.cryptocompare.com/data/price?fsym=${data}&tsyms=USD`,  
        }
        let Resp =  await axios(senddata);
        
        return Resp.data?.USD;
    }
    catch(err){
        return 0
    }
   
}
export const TOKENPRICE = async (data) =>{
    try{
        let  senddata ={
            'method': 'get',
            'url': `https://api.pancakeswap.info/api/v2/tokens/${data}`,  
        }
        let Resp =  await axios(senddata);
        return Resp?.data?.data?.price;
    }catch(err){
        console.log("tokenprice_err",err);
        return 0
    }
   
}