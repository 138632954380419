import { combineReducers } from "redux";
import { Data_Section, Account_Section, ServiceFees_Section,Admin_Section ,Stake_Section,Logedin,Pooldata,Available, Settings} from "./constants";

const Initial_State = {
  currency : [],
  Categorys:[],
  USD:{
    BNB : '',
  },
  User:{
    token : '',
    payload : null
  },
  CMS: {
    impactcollectivemarketplace   :   '',
    latestdrop   :   '',
    featuredartist   :   '',
    footer   :   '',
    aboutus   :   '',
    contactus   :   '',
    termsofservice   :   '',
    privacypolicy   :   '',
},
    AccountDetails:{
        accountAddress : '',
        tokenBalance : 0,
        coinBalance : 0,
        web3        : null,
        web3p : null
    },
    ServiceFees:{
      buyerFees : '0',
      sellerFees : '0',
      baseRoyalty : '0'
    },
    AdminAddress: "",
    isStake : false,
    Logedin:false,
    Pooldata : {},
    Available:[],
    balance : 0,
    Settings:{}
  
  }


function LoginReducer(state = Initial_State, action) {
    switch (action.type) {
      case Data_Section:
        return {
          ...state,
          ...action.Register_Section
        }
      case Account_Section:
        return {
          ...state,
          ...action.Account_Section
        }
        case ServiceFees_Section:
          return {
            ...state,
            ...action.ServiceFees_Section
          }
          case Admin_Section:{
            return{
              ...state,
              ...action.Admin_Section
            }
          }
          case Logedin:{
            return{
              ...state,
              ...action.Logedin
            }
          }
          case Stake_Section:{
            return{
              ...state,
              ...action.Stake_Section
            }
          }
          case Pooldata:{
            return{
              ...state,
              ...action.Pooldata
            }
          }
          case Available :{
            return {
              ...state,
              ...action.Available
            }
          }
          case Settings :{
            return {
              ...state,
              ...action.Settings
            }
          }
      default:
        return state;
    }
  }  

  


const ImpactApp = combineReducers({LoginReducer:LoginReducer});

export default ImpactApp;