import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Config from "../../src/components/config/config";
import marketAbi from '../Abi/market.json'
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { useWeb3React } from '@web3-react/core'

export const connectWallet = async (type) => {
  var accountDetails = {}
  var web3Obj = {}
  if (type == "MetaMask" || type == 'BinanceWallet' || type == 'Coinbase' || type == 'CoinbaseMobile') {
    web3Obj = await MetamaskWallet(type)
  }
  if (type == 'WalletConnect') {
    web3Obj = await WalletConnect(type)
  }
  if (web3Obj) {

    try {
      let  web3p = new Web3(Config.RPC_URL)
      const accounts = await web3Obj.eth.getAccounts();
      accountDetails.accountAddress = accounts[0]?.toString()?.toLowerCase();
      accountDetails.coinBalance = await web3Obj.eth.getBalance(accountDetails.accountAddress);
      accountDetails.web3p = web3p;

      accountDetails.web3 = web3Obj;
      accountDetails.tokenBalance = 0
      return accountDetails;
    }
    catch (e) {
      return accountDetails;
    }
  }
}

export const MetamaskWallet = async (type) => {

  var web3
  try {
    if (window.ethereum && type == 'MetaMask') {
      web3 = new Web3(window.ethereum);
      if (window.ethereum.isMetaMask) {

        const chainId = await web3.eth.getChainId();
        if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
          chainIdCheck()

        }
        await window.ethereum.enable()
        .then(async () => {

          const accounts =  await web3.eth.getAccounts();
          const account = accounts[0].toString().toLowerCase();
          localStorage.setItem("accountInfo", account)
          localStorage.setItem('walletConnectType', type)
        });

      }
      else {
        alert("Please Uninstall CoinbaseWallet or Connect to Coinbase")
        return false;
      }


    }
     if (window.BinanceChain && type == 'BinanceWallet') {
      web3 = new Web3(window.BinanceChain);
      const chainId = await web3.eth.getChainId();

      if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
        chainIdCheck()
        return true
      }
      await window.BinanceChain.enable().then(async () => {
        // User has allowed account access to DApp...
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0].toString().toLowerCase();
        localStorage.setItem("accountInfo", account)
        localStorage.setItem('walletConnectType', type)
      });
    }
     if (window.ethereum && type == 'Coinbase') {


      if(typeof window.ethereum === "undefined"){
        return;
        }
        
        //connect to coinbase
        
         let provider = window.ethereum;
         web3 = new Web3(provider)
         const accounts=await provider.request({
                method: "eth_requestAccounts",
              });
              const chainId = await web3.eth.getChainId();
              if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
                chainIdCheck()
                return true
              }


              const account = accounts[0].toString().toLowerCase();
              localStorage.setItem("accountInfo", account)
              localStorage.setItem('walletConnectType', type)

    }
    // Legacy DApp Browsers
    //check
    if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
      const chainId = await web3.eth.getChainId();
      if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
        chainIdCheck()
        return true
      }
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0].toString().toLowerCase();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)
    }
    // Non-DApp Browsers
    else {
      alert('No Dapp Supported Wallet Found');
    }

  } catch (e) {
    console.log("accountDetails type id1 last",e)
  }

  return web3;

}

export const WalletConnect = async (type) => {

  var  CHAIN_ID = Config.CHAIN_ID;
  try {
    if (localStorage.getItem("accountInfo")) {

      const provider = await EthereumProvider.init({
        projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        chains: [CHAIN_ID], // required
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.enable()

      var web3 = new Web3(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
      var account = accounts[0].toString();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)
      return web3;
    }
    else {

      const provider = await EthereumProvider.init({
        projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        chains: [CHAIN_ID], // required
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.connect()

      var web3 = new Web3(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
      var account = accounts[0].toString();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)

      return web3;
    }
  }
  catch (err) {
    console.log(err)
  }

}



const chainIdCheck = async (e) => {

  const hexString = Config.CHAIN_ID.toString(16);

  if (window.ethereum) {
    try {


      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
      });
      return true;
    } catch (error) {

      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: "0x" + hexString,
                rpcUrl: Config.RPC_URL,
              },
            ],
          });
          return true;
        } catch (addError) {
          console.error(addError);
        }
      }
    }
  } else {

    return false;

  }
}

export const getServiceFees = async () => {
  var rpcObj = new Web3(Config.RPC_URL)
  var fees = {}
  if (rpcObj) {
    try {
      let  marketObj = new rpcObj.eth.Contract(
        marketAbi,
        Config.TradeContract
      );
      let  servicefees = await marketObj.methods.getServiceFee().call()
      fees.buyerFees = servicefees[0]
      fees.sellerFees = servicefees[1]

      return fees;
    }
    catch (e) {
      console.log("service fees catch blok running", e)
    }
  }
}
