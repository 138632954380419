import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import reveal_img from '../assets/images/reveal_card.png';
import add_img from '../assets/images/add_btn.png';
import Revealmodal from './modal/revealmodal';
import { useSelector } from 'react-redux';
import config from './config/config'
import { MediaRenderer } from "@thirdweb-dev/react";
// import { useIPFS } from "react-ipfs";

export default function Charcard({
  char_img,
  type,
  product,
  index
}
) {
  console.log('product-->',product)
  // const ipfs = useIPFS()
  const [revealModal, setRevealModal] = useState(false);
  const [types, setType] = useState()
  const { accountAddress, web3 } = useSelector(state => state.LoginReducer.AccountDetails);
  const { AdminAddress } = useSelector((state) => state.LoginReducer);

  const setRevealorstake = async () => {
    setType(type)
    setRevealModal(true);
  }

//   async function loadImgURL(cid, mime, limit) {
//     if (cid == "" || cid == null || cid == undefined) {
//         return;
//     }
//     const content = [];
//     for await (const chunk of ipfs.cat(cid, {length:limit})) {
//         content.push(chunk);
//     }
//     return URL.createObjectURL(new Blob(content, {type: mime}));
// }



  return (
    <div>
      {
        type == "equipments" ?
          <div className='card char_card_style create_card'>
            {
            // product?.CompressedFile && 
            
            <img 
            src={
              product?.NFTOrginalImageIpfs
              // `${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`
            } 
            className='img-fluid char_img' 
            alt='char_img' 
            />
            }
            <div className='detail_sec'>  
              <div>
                <p className='title'>NAME</p>
                <p className='title_desc'>{product?.NFTName}</p>
              </div>
              <div>
                
              </div>
              <div>
                <p className='title'>COUNT</p>
                <p className='title_desc'>{accountAddress == AdminAddress ? product?.NFTBalance : product?.NFTOwner?.filter((val) => val.owner == accountAddress)?.reduce((accumulator, object) => {
                  return accumulator + object.quantity;
                }, 0)}</p>
              </div>
            </div>
            <div className='shadow_blur'></div>
          </div>
          :
          type == 'character' ?
            <>
              {
                ((accountAddress == AdminAddress)) ?
                  <div className='card char_card_style create_card'>
                    {
                    // product?.CompressedFile && 
                    <img 
                    src={
                      product?.NFTOrginalImageIpfs
                      // `${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`
                      } 
                      className='img-fluid char_img' 
                      alt='char_img' />
                      }
                    <div className='detail_sec'>
                      <div>
                        {/* <p className='title'>NAME</p>
      <p className='title_desc'>{product?.NFTName}</p> */}
                      </div>
                      <div>
                        <p className='title'>NAME</p>
                        <p className='title_desc'>{product?.NFTName}</p>
                      </div>
                      <div>
                        {/* <p className='title'>TYPE</p>
      <p className='title_desc'>{product?.typename}</p> */}
                      </div>
                    </div>
                    <div className='shadow_blur'></div>
                  </div>
                  :
                  (!product.isReveal) ?
                    <div className='card char_card_style'>
                      <img src={reveal_img} className='img-fluid char_img' alt='char_img' />
                      <div className='reveal_sec'>
                        <Link className="reveal_btn" onClick={() => setRevealorstake()} >REVEAL</Link>
                      </div>
                    </div>
                    :
                    <div className='card char_card_style create_card'>
                      {
                      // product?.CompressedFile && 
                      <img 
                      src={
                        product?.NFTOrginalImageIpfs
                        // `${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`
                        } 
                        className='img-fluid char_img' 
                        alt='char_img' 
                        />
                        }
                      {
                      product?.isStake == true
                       ? 
                      <>
                      <div className='detail_sec'>
                        <div>
                        <p className='title'>NAME</p>
                          <p className='title_desc'>{product?.NFTName}</p>
                        </div>
                        <div>
                         
                        </div>
                        <div>
                        <p className='title'>STATUS</p>
                          <p className='title_desc'>{'STAKED'}</p>
                        </div>
                      </div>
                        <div className='shadow_blur'></div>
                      </>
                      :
                      <>
                       <div className='detail_sec'>
                        <div>
                       
                        </div>
                        <div>
                          <p className='title'>NAME</p>
                          <p className='title_desc'>{product?.NFTName}</p>
                        </div>
                        <div>
                        
                        </div>
                      </div>
                      </>}
                      <div className='shadow_blur'></div>
                    </div>
              }
            </>
            :
            type == "preview" ?
              <>
                {

                  <div className='card char_card_style create_card'>
                    {
                      <img src={
                        product?.image
                      // URL.createObjectURL(product.file)
                      } className='img-fluid char_img' alt='char_img' />
                      }
                    <div className='detail_sec'>
                      <div>
                      </div>
                      <div>
                        <p className='title'>NAME</p>
                        <p className='title_desc'>{product?.name}</p>
                      </div>
                      <div>

                      </div>
                    </div>
                    <div className='shadow_blur'></div>
                  </div>

                }
              </>
              :
              type == "folder" ?
                <>
                  {

                    <div className='card folder_card'>
                      <div className='inner_card_sec'>
                        <div>  <p className='name'>Drop {index+1}</p></div>
                        <div className='bottom_det_sec'>
                          <div>
                            <p className='label_card'>Price</p>
                            <p className='label_value'>{product?.Dropprice}{" "}{product?.Dropcoin}</p>
                          </div>
                          <div>
                            <p className='label_card'>Qty</p>
                            <p className='label_value'>{product?.DropNFTs?.length}</p>
                          </div>
                        </div>

                      </div>

                    </div>

                  }
                </>
                :
                type == 'sale' ?
                  <>
                    {
                      <div className='card char_card_style create_card'>
                        { 
                          <img 
                          src={
                            product?.NFTOrginalImageIpfs
                            // `${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`
                            }
                             className='img-fluid char_img' 
                             alt='char_img' 
                             />
                             }
                        <div className='detail_sec'>
                          <div>
                            <p className='title'>STATUS</p>
    <p className='title_desc'>{product?.Saled ? 'Sold' : 'Not Saled'}</p>
                          </div>
                          <div>
                            
                          </div>
                          <div>
                          <p className='title'>NAME</p>
                            <p className='title_desc'>{product?.NFTName}</p>
                          </div>
                        </div>
                        <div className='shadow_blur'></div>
                      </div>


                    }
                  </>
                  :
                  type == 'salelater' ?
                    <>
                      {

                        <div className='card char_card_style create_card salelater_card'>
                          
                          {
                            <img 
                            src={
                              product?.NFTOrginalImageIpfs
                              // `${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`
                              } 
                              className='img-fluid char_img' 
                              alt='char_img' 
                              />
                              }
                          <div className='detail_sec'>
                            <div>
                              
                            </div>
                            <div>
                              <p className='title'>NAME</p>
                              <p className='title_desc'>{product?.NFTName}</p>
                            </div>
                            <div>
                             
                            </div>
                          </div>
                          <div className='shadow_blur'></div>
                        </div>


                      }
                    </>
                    :
                    <>
                      <div className='card char_card_style create_card detail_create_card'>

                        <img src={char_img} className='img-fluid create_img' alt='create_img' />

                        <div className='btn_sec'>
                          {!char_img?.split('/')[char_img?.split('/')?.length - 1].includes('.webp') && <img src={add_img} className='img-fluid add_img' alt='add_img' />}

                        </div>
                        
                        <div className='detail_sec'>
                          <div>
                            <p className='title'>NAME</p>
                            <p className='title_desc'>{product?.NFTName}</p>
                          </div>
                          <div>
                            
                          </div>
                          <div>
                            <p className='title'>TYPE</p>
                            <p className='title_desc'>{product?.typename}</p>
                          </div>
                          
                        </div>
                        <div className='shadow_blur'></div>
                      </div>
                    </>




      }
      <Revealmodal
        show={revealModal}
        type={types}
        onHide={() => setRevealModal(false)}
        product={product}
      />

    </div>
  )
}
