// import ERC721 from '../../src/Abi/erc721.json'
// import ERC1155 from '../../src/Abi/erc1155.json'
// import TRADE from '../../src/Abi/market.json'
var EnvName = 'production';
var key = {};
key.KEY = 'S@v@g3Cr0wn'
key.ONEDAYINSECONDS = 0
key.BLOCKS_PER_YEAR = 0
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE = /^\d{10}$/
key.NumOnly = /^\d+$/
key.PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil = /^[a-zA-Z-9]+$/
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/
key.IPFS = 'https://ipfs.io/ipfs/'
key.Dummyipfs = 'Qme9ojN3frFzKLBVrSZKmB2qQuAcXPWeuKF8dnHnWb5QGp'
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.BUYCOINNAME = "ETH"
key.NumberOnly = /[^0-9]/g

if (EnvName === "demo") {
    key.FRONT_URL = 'http://nftdemo.bimaticz.com/savagecrown'
    key.BACK_URL = 'http://192.53.121.26:7018/v1/front'
    key.ADMIN_URL = 'http://192.53.121.26:7018/v1/admin'
    key.IMG_URL = 'http://192.53.121.26:7018'
    key.DefaultImg = 'http://192.53.121.26:7018/default.jpg'
    key.CoverImg = 'http://192.53.121.26:7018/demobanner.jpg'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.ERC721 = '0xF0DA72F3CE27fFA9bD1326644FaDc5574e8aE827'.toLowerCase()
    key.ERC1155 = '0x1b2D2f0A7a40c139011692a5148480DA26647245'.toLowerCase()
    key.erc20Address = '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'.toLowerCase()
    key.StakeAddress = "0xDa9f2E3739447F7C36FC37FB55a53f8ea1C08b78".toLowerCase()
    key.CHAIN_ID = 97
    key.poolid = 3
    key.Block_URL = "https://testnet.bscscan.com/"
    key.COIN_NAME = "BNB"
    key.RPC_URL = "https://bsc-testnet-rpc.publicnode.com"
}
else if (EnvName === "stage") {
    key.FRONT_URL = 'http://nftdemo.bimaticz.com/savagecrown'
    key.BACK_URL = 'https://stagingbackend.savagetale.com/v1/front'
    key.ADMIN_URL = 'https://stagingbackend.savagetale.com/v1/admin'
    key.IMG_URL = 'https://stagingbackend.savagetale.com'
    key.DefaultImg = 'https://stagingbackend.savagetale.com/default.jpg'
    key.CoverImg = 'https://stagingbackend.savagetale.com/demobanner.jpg'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.ERC721 = '0xF0DA72F3CE27fFA9bD1326644FaDc5574e8aE827'.toLowerCase()
    key.ERC1155 = '0x1b2D2f0A7a40c139011692a5148480DA26647245'.toLowerCase()
    key.erc20Address = '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'.toLowerCase()
    key.StakeAddress = "0xDa9f2E3739447F7C36FC37FB55a53f8ea1C08b78".toLowerCase()
    key.CHAIN_ID = 97
    key.poolid = 3
    key.Block_URL = "https://testnet.bscscan.com/"
    key.COIN_NAME = "BNB"
    key.RPC_URL = "https://bsc-testnet-rpc.publicnode.com"
}
else if (EnvName === "production") {
    key.FRONT_URL = 'http://nftdemo.bimaticz.com/savagecrown'
    key.BACK_URL = 'https://backend.savagetale.com/v1/front'
    key.ADMIN_URL = 'https://backend.savagetale.com/v1/admin'
    key.IMG_URL = 'https://backend.savagetale.com'
    key.DefaultImg = 'https://backend.savagetale.com/default.jpg'
    key.CoverImg = 'https://backend.savagetale.com/demobanner.jpg'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.ERC721 = '0xF0DA72F3CE27fFA9bD1326644FaDc5574e8aE827'.toLowerCase()
    key.ERC1155 = '0x1b2D2f0A7a40c139011692a5148480DA26647245'.toLowerCase()
    key.erc20Address = '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'.toLowerCase()
    key.StakeAddress = "0xDa9f2E3739447F7C36FC37FB55a53f8ea1C08b78".toLowerCase()
    key.CHAIN_ID = 97
    key.poolid = 3
    key.Block_URL = "https://testnet.bscscan.com/"
    key.COIN_NAME = "BNB"
    key.RPC_URL = "https://bsc-testnet-rpc.publicnode.com"
}
else if (EnvName === "demossl") {

}
else {
    key.FRONT_URL = 'http://localhost:3000/Ditmax'
    key.BACK_URL = 'http://localhost:3331/v1/front'
    key.ADMIN_URL = 'http://localhost:3331/v1/admin'
    key.IMG_URL = 'http://localhost:3331'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.ERC721 = '0xF0DA72F3CE27fFA9bD1326644FaDc5574e8aE827'.toLowerCase()
    key.ERC1155 = '0x1b2D2f0A7a40c139011692a5148480DA26647245'.toLowerCase()
    key.erc20Address = '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'.toLowerCase()
    key.StakeAddress = "0xDa9f2E3739447F7C36FC37FB55a53f8ea1C08b78".toLowerCase()
    key.RPC_URL = "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    key.CHAIN_ID = 97
    key.poolid = 3
    key.Block_URL = "https://testnet.bscscan.com/"
    key.COIN_NAME = "BNB"
}
export default key;
