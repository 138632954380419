import { emitWarning } from 'process'
import React, { useEffect, useRef } from 'react'

function Loader() {
  const loadermain = useRef(null);
  const loades = useRef(null);
  const hide = useRef(null);
  useEffect(() => {
  setTimeout(()=>{
    loadermain.current.classList.add("anims")
    loades.current.classList.add("animmain")
  },1300)
  setTimeout(()=>{
    hide.current.classList.add("d-none")
  },1800)
},[])


  return (
    <>
   
 <div  ref={loades} >
<div id='loades' ref={loadermain} className='loader_main'>
    <div className='loader_whole' ref={hide}>
      <div className='loader_cont_aling'>
        <img className='loader_center_img' width={100} height={70} src={require('../assets/images/new_img/loadinglogo.webp')}/>
        <p className='loader_loadtxt'>Loading...</p>
        </div>
    </div>
    </div>
    </div>
   
    </>
  )
}

export default Loader;