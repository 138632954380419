import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Tab, Tabs } from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import coin from '../assets/images/coin.png'
import QuestCarddetail from '../components/questcarddetail';
import CharCard from '../components/charcard';
import Nodata from '../components/nodata';
import Questselect from '../components/modal/questselectmodel';
import { useDispatch, useSelector } from 'react-redux';
import inner_line from '../assets/images/inner_line.png'
import HeaderOne from '../layout/HeaderOne';
import imgs from '../assets/images/char_img.png'
import config from '../components/config/config'
import { toast } from 'react-toastify';
import useContractProviderHook from "../actions/contractProviderHook";
import { Enablestake } from '../actions/axios/nftaxios';
import Web3 from 'web3'
import randomInteger from 'random-int';
import { isEmpty } from '../actions/common';



export default function Questdetail() {
  const location = useLocation()
  const navigate = useNavigate()
  const questtabpage = location.state?.data.type;

  const data = location.state?.data
  const { balance,Settings } = useSelector((state) => state.LoginReducer)
  const [openmodel, setOpenmodel] = useState(false)
  const { web3, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const dispatch = useDispatch();
  const { payload } = useSelector((state) => state.LoginReducer.User);

  const { AdminAddress, isStake } = useSelector((state) => state.LoginReducer);

  const [nftscount, setNftcount] = useState([])
  const [index, setIndex] = useState()

  const [approve, setApprove] = useState()

  const ContractCall = useContractProviderHook();
  useEffect(()=>{
    if(!isEmpty(Settings) && !Settings?.walletsection){
      navigate('/')
    }
  },[Settings])

  useEffect(() => {
    if (web3) {
      getapprovestatus()
    }
  }, [web3])

  const getapprovestatus = async () => {
    let Statu = await ContractCall.GetApproveStatus("Single", config?.ERC721, true);
    setApprove(Statu)
  }



  const [charcard, setCharcard] = useState([
    {
      char_img: require('../assets/images/create_card.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },
    {
      char_img: require('../assets/images/create_card.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },
    {
      char_img: require('../assets/images/create_card.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },

    {
      char_img: require('../assets/images/create_card.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },

    {
      char_img: require('../assets/images/create_card.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },

    {
      char_img: require('../assets/images/create_card.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },

    {
      char_img: require('../assets/images/create_card.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },

    {
      char_img: require('../assets/images/create_card.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },

    {
      char_img: require('../assets/images/create_card.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },

    {
      char_img: require('../assets/images/create_card.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },



  ])
  useEffect(() => {
    setNftcount(charcard.slice(0, data?.nftscount))
  }, [])

  const selectnft = async (data, index) => {
    let  count = nftscount
    count[index] = data
    setNftcount(count)
    setOpenmodel(false)
  }

  const Tokenstake = async (info) => {
    if (accountAddress != AdminAddress) {
      let  poolid = data.poolid;
      let contAdd = nftscount?.map((item) => item.ContractAddress)[0]
      let NFTId =[]
       nftscount?.map((item) =>
      {if(item.NFTId){
        NFTId.push(item.NFTId)
      }}

      )
      
      const id = toast.loading(info ? "Staking on Process" : 'unStaking on Process');

      let  hash;
      if (info) {
          if (Number(data?.nftscount) == Number(NFTId.length)) {
            let signhash = await _signcall()
            if (!approve) {
              let  cont = await ContractCall.SetApproveStatus("Single", contAdd, true)
            }
            let contdata = [
              NFTId,
              poolid,
              [signhash?.tot, signhash?.amount],
              signhash?.signhash
            ]
            hash = await ContractCall.EnableStake(...contdata);
            if (hash && hash.status) {
              let sen_dta = {
                NFTIds: NFTId,
                Poolid: poolid,
                lockperiod: data.days,
                add: accountAddress,
                stake: true,
                pooldata : hash?.poollength
              }
              let  resp = await Enablestake(sen_dta)
              if (resp.success) {
                toast.update(id, {
                  render: "Staked successfully",
                  type: "success",
                  isLoading: false,
                  autoClose: 1000, closeButton: true, closeOnClick: true
                });

                navigate('/quest', { state: 'profile' })
              }
              else {
                toast.update(id, {
                  render: "Try Again..",
                  type: "error",
                  isLoading: false,
                  autoClose: 1000, closeButton: true, closeOnClick: true
                });
              }
            }
            else {
              toast.update(id, {
                render: "Try Again..",
                type: "error",
                isLoading: false,
                autoClose: 1000, closeButton: true, closeOnClick: true
              });
            }
          }
          else {
            toast.update(id, {
              render: `Please Select ${data?.nftscount} NFTs`, type: "error",
              isLoading: false,
              autoClose: 1000, closeButton: true, closeOnClick: true
            })
          }
      }
      else {
        let ids = await Promise.all(data?.stakeddata?.map(item => item?.NFTId))
        hash = await ContractCall.NFTWithdraw(ids, poolid,data?.pooldata)
        if (hash && hash.status) {
          let sen_dta = {
            Id: data?.id,
            get: data?.tokens,
            owner: accountAddress
          }
          var resp = await Enablestake(sen_dta)
          if (resp.success) {
            toast.update(id, {
              render: "UnStaked successfully",
              type: "success",
              isLoading: false,
              autoClose: 1000, closeButton: true, closeOnClick: true
            });
            dispatch({
              type: 'Stake_Section',
              Stake_Section: {
                isStake: !isStake
              }

            })
            navigate('/quest', { state: 'profile' })
          }
          else {
            toast.update(id, {
              render: "Try Again..",
              type: "error",
              isLoading: false,
              autoClose: 1000, closeButton: true, closeOnClick: true
            });
          }
        }
        else {
          toast.update(id, {
            render: "Try Again..",
            type: "error",
            isLoading: false,
            autoClose: 1000, closeButton: true, closeOnClick: true
          });
        }
      }
    }
  }


  const _signcall = async () => {
    let id = toast.loading("Sign is process")
    if (web3) {
      let  randomNum =
        randomInteger(10000000, 100000000);
      let  _nonce = Date.now();
      let  password = data?.hash
        let  web3RpcPro = new Web3(web3.providers);
        let  to = payload.WalletAddress
        let  tot = _nonce + Number(randomNum);
        let  result = web3RpcPro.utils.soliditySha3(to, _nonce, password, tot);
        let  signhash = await web3.eth.personal.sign(result, to);
        if (signhash) {
          toast.update(id, { render: 'Sign is Completed', type: 'success', isLoading: false, autoClose: 1000 })
          return ({ signhash: signhash, tot: tot, password: password, amount: _nonce })
        }
      
    }
  }

  return (
    <div className='inner_pages'>
      <HeaderOne />
      <div className='quest_pages quest_detail'>
        <div className='quest_detail_btm'>

        </div>
        <div className='inner_sec'>
          <div className='container inner_container'>
            <div className='top_sec'>
              <div className='row'>
                <div className='text-center mb-4'>
                  <p className='page_title'>{data?.heading ? data?.heading : "MINE IRON ORE"}</p>
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
                </div>
                <div className='desc_sec'>
                  {questtabpage == "active" && <p className='page_desc'>Ends: 03/23/2023</p>}
                  <div className='coin_sec'>
                    <p className='balance_coin'><img src={coin} alt='coin' className='img-fluid' width={25} /> x {balance}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='detail_card_sec'>
              {
                questtabpage == "available" ?
                  <div className='row'>
                    <div className='col-lg-12'>
                      <QuestCarddetail
                        quest_img={data?.quest_img ? data?.quest_img : imgs} desc={data?.desc ? data?.desc : "MINE IRON ORE"} category={data?.category} days={data?.days} nftscount={data?.nftscount} tokens={data?.tokens} type={data?.type}
                        enablestake={Tokenstake}
                      />
                    </div>
                  </div>
                  :
                  questtabpage == "active" ?
                    <div className='row'>
                      <div className='col-lg-12'>
                        <QuestCarddetail
                          quest_img={data?.quest_img ? data?.quest_img : imgs} desc={data?.desc ? data?.desc : "MINE IRON ORE"} category={data?.category} days={data?.days} nftscount={data?.nftscount} tokens={data?.tokens} type={data?.type} endtime={data?.endtime}
                        />
                      </div>
                    </div>
                    :
                    <div className='row'>
                      <div className='col-lg-12'>
                        <QuestCarddetail
                          quest_img={data?.quest_img ? data?.quest_img : imgs} desc={data?.desc ? data?.desc : "MINE IRON ORE"} category={data?.category} days={data?.days} nftscount={data?.nftscount} tokens={data?.tokens} type={data?.type} endtime={data?.endtime}
                          enablestake={Tokenstake}
                        />
                      </div>

                    </div>

              }
            </div>
            <div className='recent_quest'>
              <div className='row'>
                <div className='text-center mb-4'>
                  {
                    questtabpage == "available" ?
                      <p className='page_title'>Characters on this Quest</p> : questtabpage == "active" ? <p className='page_title'>Characters on this Quest</p> : <p className='page_title'>Characters that Completed this Quest</p>}
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
                </div>
              </div>

              {questtabpage == "available" ?
                <div className='row card_rows'>
                  {nftscount && nftscount?.map((e, i) => (
                    <div className='col-xl-3 col-lg-4 col-sm-6 mb-4' onClick={() => { setOpenmodel(true); setIndex(i) }} >
                      <CharCard
                        char_img={e.char_img ? e.char_img : `${config.IMG_URL}/nft/${e?.NFTCreator}/Compressed/NFT/${e?.CompressedFile}`} index={i} creater={e?.NFTCreator} clan={e?.clan} trait={e?.trait} level={e?.level} type={e?.type} product={e} />
                    </div>
                  ))}
                </div>
                :
                <div className='row card_rows'>
                  {data?.stakeddata?.map((e) => (
                    <div className='col-xl-3 col-lg-4 col-sm-6 mb-4'>
                      <CharCard
                        char_img={`${config.IMG_URL}/nft/${e?.NFTCreator}/Compressed/NFT/${e?.CompressedFile}`} product={e} type={e?.type} />
                    </div>
                  ))}

                </div>
              }
            </div>

          </div>


        </div>
      </div>


      <Footer />

      {questtabpage == "available" && <Questselect
        show={openmodel}
        onHide={() => setOpenmodel(false)}
        onSelect={selectnft}
        index={index}
        NFTsid={nftscount?.map((item) => item?.NFTId)}
        category={data?.category}
        types={data?.requiretype}
        questdata={data}
      />}
    </div>


  )
}
