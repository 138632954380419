import axios from 'axios'
import Config from '../components/config/config'
import CryptoJS from 'crypto-js';


export const isEmpty = value =>
value === undefined ||
value === null ||
(typeof value === 'object' && Object.keys(value).length === 0) ||
(typeof value === 'string' && value.trim().length === 0) ||
(typeof value === 'string' && value === '0')||
(typeof value === 'number' && value === 0);



export const Decryptdata = ((data,key) => {
  try{
    if(key){
  return CryptoJS.AES.decrypt(data, Config.KEY).toString(CryptoJS.enc.Utf8)
    }
    else{
      const decData = CryptoJS.enc.Base64.parse(data)?.toString(CryptoJS.enc.Utf8);
      const bytes = CryptoJS.AES.decrypt(decData, Config.KEY).toString(CryptoJS.enc.Utf8);
      return JSON.parse(bytes) 
    }
  }
  catch(err){
    console.log('Decryptdata_err-->',err)
    return data;
  }
  

})

// Address cut function like 0x123...345
export const address_showing=(item)=>{
    if(item&&item.toString().length>10){
    let  slice_front = item.slice(0,9)
    let  slice_end  = item.slice(item.length-9,item.length-1)
    return slice_front+'....'+slice_end
    }
    else return item
}
export const Name_showing=(item)=>{
  if(item&&item.toString().length>10){
  let  slice_front = item.slice(0,9)
  return slice_front+'....'
  }
  else return item
}
// Copy Text
export const copydata = (data) =>{
    let copyText = data;
     navigator?.clipboard?.writeText(copyText);
     return copyText
}

export const NumANdDotOnly = (data) => {
  var data = data.toString()
 let str = data ? data.includes('.') ? data.split('.').length >=3 ? (data.split('.')[0] + '.' + data.split('.')[1]).toString() : data : data : data
   return str.toString().replace(Config.NumDigitOnly,'')
 }

export const NumberOnly = (data) => {
  return data.toString().replace(Config.NumberOnly,'')
}

export const EncryptData = (data,key) => {
  try{
    if(key){
      return CryptoJS.AES.encrypt(data, Config.KEY).toString()
    }else{
      const encJson = CryptoJS.AES.encrypt(JSON.stringify(data), Config.KEY).toString();
      const encData = CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(encJson)
      );
      return encData;
    }
    
  }
  catch(err){
    console.log('EncryptData_err-->',err)
        return data
  }
}

// Common Formdata function
export const AppenData = (data) => {
    
  var formdata = new FormData()
  var SendDta = Object.entries(data).map((item) => {
      if (Array.isArray(item[1])) {
          var come = item[1].map(data => {
              if (data?.type && data?.size) {
                  formdata.append(item[0], data)
              }
              else {
                  formdata.append(item[0], EncryptData(data))
              }
              return formdata
          })
         
          return come
      }
      else {
          if (item[1]?.type && item[1]?.size) {
              formdata.append(item[0], item[1])
          }
          else {
              formdata.append(item[0], EncryptData(item[1]))
          }
          return formdata
      }
  })
  return [formdata]
}

// Common Axios Function
export const axiosFunc    =   async   (data)  =>  {
  try{
    let Resp = await axios(data)
    if (Resp?.data) {
        Resp.data = Resp.data ? isEmpty(Decryptdata(Resp.data)) ? Resp.data : Decryptdata(Resp.data) : Resp.data
    }
    return Resp.data
  }
  catch(e){
    console.log('axiosFunc_err-->',e)
      return {success:'error',msg:null}
  }
}

export function toFixed(x) {
  var e;
  if (Math.abs(x) < 1.0) {
     e = parseInt(x.toString().split('e-')[1]);
    if (e) {
        x *= Math.pow(10,e-1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
     e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
        e -= 20;
        x /= Math.pow(10,e);
        x += (new Array(e+1)).join('0');
    }
  }
  return x;
}

