import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useContractProviderHook from "../actions/contractProviderHook";
import config from "../components/config/config";
import { toast } from "react-toastify";
import { isEmpty } from "../actions/common";
import coin from '../assets/images/coin.png'
import file from '../assets/images/file.png'
import Dropzone from "react-dropzone";
import randomInteger from 'random-int';
import Web3 from 'web3'
import Select from "react-select";
import { imgageupload } from '../actions/axios/useaxios';
import { gettype, addpools } from '../actions/axios/nftaxios';
import HeaderOne from '../layout/HeaderOne';
import * as XLSX from 'xlsx';
import Charcard from '../components/charcard';
import axios from 'axios'


export default function Minting() {

  const location1 = useLocation();
  const push = useNavigate();
  const contract = useContractProviderHook()
  const handleFileUploadClick = () => {
    const fileInput = document.getElementById('NFTOrginalImage');
    fileInput.click();
  };

  const category = [{ label: "Character", value: "Charecter" }, { label: "Equipments", value: "Equipments" }, { label: "Quest", value: "Quest" }]

  const {  web3, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  const { payload } = useSelector(
    (state) => state.LoginReducer.User
  );
  const { AdminAddress } = useSelector(
    (state) => state.LoginReducer
  );
  const [status, setStatus] = useState(true)

  const { currency,Settings } = useSelector((state) => state.LoginReducer);

  useEffect(()=>{
    if(!isEmpty(Settings) && !Settings?.walletsection){
      push('/')
    }
  },[Settings])

  useEffect(() => {
    if (AdminAddress && payload?.WalletAddress && AdminAddress !== payload?.WalletAddress) {
      if (status) {
        toast.error("Sorry Admin only allowed")
        push('/')
        setStatus(false)
      }
    }
  }, [AdminAddress, payload])

  const [prev, setPrev] = useState(false)
  const [filename, setFileName] = useState('')
  const initialTokenValue = {
    NFTRoyalty: "",
    NFTCreator: accountAddress,
    NFTOwner: accountAddress,
    ContractAddress: "",
    ContractType: "",
    Category: '',
    CoinName: "",
    CollectionNetwork: config.COIN_NAME,
    imgfile: [],
    jsonfile : {}

  };

  const [NFTFormValue, setNFTFormValue] = useState(initialTokenValue);
  const [FormButton, SetFormButton] = useState("start");
  const [ValidateError, SetValidateError] = useState({});
  const [MintButton, setMintButton] = useState("stop");
  const [prevdata, setPredata] = useState([])
  const fileInputRef = useRef(null);
  const onChange = (e, type) => {
    const { files, value, id, name } = e.target;
    SetFormButton("start")
    if (!type) {
      const { files, value, id, name } = e.target;
      if (id == "NFTRoyalty" || id == "NFTPrice" || id == "NFTMinimumBid") {
        const checkprice = /^\d*\.?\d*$/;
        if (checkprice.test(value)) setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
      }
      else {
        setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
      }
    }
    if (type == 'Orginal') {
      setNFTFormValue({
        ...NFTFormValue,
        ...{ ["imgfile"]: [...files] },
      });
      toast.success("Image Folder Uploaded")
    }
    if (type == "excel") {
      let  fileNameExt = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf(".") + 1)
      if (fileNameExt == "xlsx") {
        setFileName(e.target.files[0].name)
        setNFTFormValue({
          ...NFTFormValue,
          ...{ ["excelfile"]: e.target.files[0] },
        });
        toast.success("Excel file Uploaded")
      }
      else {
        toast.warning("Please upload Excel file only")
      }
    }
    if(type == 'json'){
      let  fileNameExt = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf(".") + 1)
      if (fileNameExt == "json") {
        setFileName(e.target.files[0].name)
        setNFTFormValue({
          ...NFTFormValue,
          ...{ ["jsonfile"]: e.target.files[0] },
        });
        toast.success("file Uploaded")
      }
      else {
        toast.warning("Please upload json file only")
      }
    }
  };

  useEffect(() => {
    if (
      // NFTFormValue?.imgfile.length > 0 
      // && 
      NFTFormValue?.jsonfile?.name) {
      previewfunc()
    }

  }, [NFTFormValue?.jsonfile])

  const previewfunc = () => {
    let file = NFTFormValue.jsonfile
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = async(e) => {
      let jsondata = JSON.parse(e.target.result)
      console.log('jsondata-->',jsondata)
      // jsondata.map((val)=>{
      //   val.file = NFTFormValue.imgfile.filter((vals) => vals.name == val.imagename)[0];
      //   return val
      // })
      setPredata(jsondata)
    };
  }


  const Validation = async (data) => {
    
    const {
      NFTPrice,
      NFTRoyalty,
      Category,
      CoinName,
      imgfile,
      jsonfile
    } = data;
    let ValidateError = {};
    if (!NFTRoyalty) ValidateError.NFTRoyalty = "Royalty Required";
    else if (isEmpty(NFTRoyalty))
      ValidateError.NFTRoyalty = "Royalty Must Be Greate Than 0";
    else if (isNaN(NFTRoyalty) === true)
      ValidateError.NFTRoyalty = "Royalty must be a number";
    else if (Number(NFTRoyalty) < 0) ValidateError.NFTRoyalty = "Royalty must be Greater than 0"
    else if (Number(NFTRoyalty) > 20) ValidateError.NFTRoyalty = "Royalty Must be less than 20";
    else if (Number(NFTRoyalty) % 1 !== 0) ValidateError.NFTRoyalty = "Royalty must be a Whole Number"
    if (
      isEmpty(NFTPrice)
    )
      ValidateError.NFTPrice = "NFTPrice Required";
    if (
      !CoinName
    )
      ValidateError.CoinName = "CoinName Required";
    // if (imgfile.length == 0 && Category == "") {
    //   ValidateError.NFTOrginalImage = "File/Folder Required";
    // }
    if (!jsonfile) {
      ValidateError.jsonfile = "jsonfile Required";
    }
    if (!Category) {
      ValidateError.Category = "Category is Required"
    }
    if (isNaN(NFTPrice) === true)
      ValidateError.NFTPrice = "NFT Price Should Be a Number";
    else if (Number(NFTPrice) <= 0)
      ValidateError.NFTPrice = "NFTPrice should be above Zero";
   
    return ValidateError;
  };


  const excelread = async () => {
    let  ValidateError = {}
    if (!NFTFormValue.excelfile) {
      ValidateError.excelfile = "Excel file Required";
    }
    if (NFTFormValue.imgfile.length == 0) {
      ValidateError.NFTOrginalImage = "File/Folder Required";
    }
    SetValidateError(ValidateError)
    if (Object.keys(ValidateError).length > 0) {
      toast.error("Please Upload all files")
    } else {
      let  datas;
      let  f = NFTFormValue.excelfile
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of objects */
        const data = XLSX.utils.sheet_to_json(
          ws);
        /* Update state */
        datas = data
        imageUpload(data)

      };
      reader.readAsBinaryString(f);
    }


  }




  const imageUpload = async (datas) => {

    if (NFTFormValue.Category == "Quest") {
      const id = toast.loading("Quest Adding Process");
      var Senddata = [];
      var caldata = {
        rewardToken : [],
        rewardPer : [],
        lockPeriod : [],
        poolid : [],
        enablestack : [],
        poolvalue : [],
        qty : []
      }
      var error = {}
      var erc20 = currency.filter((val) => val.value != "BNB" && val.value != "ETH").map(val => val.address)
      
      await Promise.all(datas?.map(async (data, i) => {
       
        //checking for triat value match
        let triate = data?.trait.includes(',') ? data?.trait?.split(',') : [data?.trait]
        let value = data?.value.includes(',') ? data?.value?.split(',') : [data?.value]
        
        if (triate.length != value.length) {
          error["requiretypes"] = "Please Check traittype and value"
        }

        let requiretypes =[]
          triate?.map((val,i)=>{
            requiretypes.push({[val] :value[i]})
        })
        
        if ((Number(data.Lockperiod) % 1) != 0) {
          error["Lockperiod"] = `Lockperiod must be a number in ${i} record`
        }
        if ((Number(data.Tokenqantity) % 1) != 0) {
          error["Tokenqantity"] = `Tokenqantity must be a number in ${i} record`
        }
       
        caldata['rewardToken'].push(data?.RewardToken)
        caldata['rewardPer'].push(String(Number(data?.Tokenqantity) * 1e18))
        caldata['lockPeriod'].push( String(Number(data?.Lockperiod) * 1e18))
        caldata['poolid'].push('0')
        caldata['enablestack'].push(true)
        const passwords = require('secure-random-password');
      var password = passwords.randomPassword({ length: 10, characters: [passwords.lower, passwords.upper, passwords.digits] })
        caldata['poolvalue'].push(password)
        caldata['qty'].push(data?.stakecount)

        Senddata.push({
          heading: data.heading,
          Lockperiod: data.Lockperiod,
          RewardToken: data.RewardToken,
          Description: data?.Description,
          Tokenqantity: data?.Tokenqantity,
          // Requirments: total,
          Requirments : data?.stakecount,
          requiretype: requiretypes,
          img: data.image,
          hash:password
        })
      }))
console.log('caldata-->',caldata,'Senddata-->',Senddata)

      if (Object.keys(error).length > 0) {
        console.log(Object.values(error));
        setTimeout(() => {
          toast.update(id, { render: Object.values(error)[0], type: "error", isLoading: false, autoClose: 1000 })
        }, 1000);
      }
      else {
        let  callstatus = []
          let  call = await contract.addpool(Object.values(caldata))
          callstatus.push(`${call.status}`)

        if (callstatus.includes("false")) {
          setTimeout(() => {
            toast.update(id, { render: "Try again", type: "error", isLoading: false, autoClose: 1000 })
          }, 1000);
        } else {
          let  sendfiles = {}
          sendfiles.datas = JSON.stringify(Senddata)
          sendfiles.img = NFTFormValue.imgfile
          sendfiles.from = 'excel'
          sendfiles.filter = 'add'
          console.log(NFTFormValue.imgfile);
          var Resp = await addpools(sendfiles)
          if (Resp?.status) {
            setTimeout(() => {
              toast.update(id, { render: Resp.msg, type: "success", isLoading: false, autoClose: 1000 })
            }, 1000);
            setTimeout(() => {
              push('/')
            }, 1000);
          }
          else {
            setTimeout(() => {
              toast.update(id, { render: Resp.msg, type: "error", isLoading: false, autoClose: 1000 })
            }, 1000);
          }
        }


      }

    } else {
      if (accountAddress) {
        let  Error = await Validation(NFTFormValue);
        SetValidateError(Error)
        if (isEmpty(Error)) {
          const id = toast.loading("Minting Processing");
          setMintButton("process");
          let  sendfiles = {}
          let  hash = await _signcall()
          // if (NFTFormValue?.imgfile?.length > 0) {
            // sendfiles.imgfile = NFTFormValue?.imgfile
            sendfiles.jsonfile = NFTFormValue?.jsonfile
            sendfiles.creatoraddress = accountAddress
            sendfiles.royalty = NFTFormValue?.NFTRoyalty
            sendfiles.price = NFTFormValue?.NFTPrice
            sendfiles.Hash = hash.signhash
            sendfiles.nonce = hash.tot
            sendfiles.randomname = hash.password
            sendfiles.coinname = NFTFormValue.CoinName
            sendfiles.category = NFTFormValue.Category
            sendfiles.Dropname = NFTFormValue.Dropname
            sendfiles.conctractaddress = (NFTFormValue.Category == "Character") ? config.ERC721 : config.ERC1155
            var Resp = await imgageupload(sendfiles)
            toast.update(id, {
              render: Resp?.msg,
              type: Resp?.success,
              isLoading: false,
              autoClose: 1000, closeButton: true, closeOnClick: true
            });
            if (Resp?.status) {
              setMintButton("done");
              toast.update(id, {
                render: "Token Minted successfully",
                type: "success",
                isLoading: false,
                autoClose: 1000, closeButton: true, closeOnClick: true
              });
              setTimeout(() => {
                push('/')
              }, 2000);
            } else {
              toast.update(id, {
                render: Resp?.msg,
                type: "error",
                isLoading: false,
                autoClose: 2000, closeButton: true, closeOnClick: true
              });
              setMintButton("try");
            }
          // }
        }
      }
      else {
        toast.warning("Please Connect Wallet")
      }
    } 

  }
  const _signcall = async () => {
    if (web3) {
      const passwords = require('secure-random-password');
      let   TokenPrice = NFTFormValue.NFTPrice
      let  randomNum =
        randomInteger(10000000, 100000000);
      let  password = passwords.randomPassword({ length: 10, characters: [passwords.lower, passwords.upper, passwords.digits] })
      if (web3) {
        let  web3RpcPro = new Web3(web3.providers);
        let  to = payload.WalletAddress
        let  _amount = (TokenPrice == "" || TokenPrice == undefined) ? 0 : web3.utils.toWei(String(TokenPrice));
        let  _nonce = Date.now();
        let  tot = _nonce + Number(randomNum);
        let  result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
        let  signhash = await web3.eth.personal.sign(result, to);
        console.log('signhash-->',signhash)
        if (signhash) {
          return ({ signhash: signhash, tot: tot, password: password })
        }
      }
    }
  }
  const handleUpload = () => {
    fileInputRef.current.click();
  };

  return (
    <div className='inner_pages'>
      <HeaderOne />
      <div className='quest_pages mining_pages'>
        <div className='inner_sec'>
          <div className='container inner_container'>
            <div className='top_sec'>
              <div className='row'>
                <p className='page_title'>Minting</p>
                <p className='page_desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
            </div>

            <div className='row mint_form justify-content-center'>
              <div className='prev_sec'>
                {NFTFormValue?.Category != 'Quest' && <button className='btn btn-minting' onClick={() => { 
                  (
                    // NFTFormValue?.imgfile.length > 0 
                    // && 
                    NFTFormValue?.jsonfile
                    ) ? setPrev(!prev) : toast.error("Please Upload image/ excel file") }}>{prev ? "BACK" : "Preview"}</button>}
              </div>
              {prev ?
                <div className='row'>
                  {
                    prevdata?.length > 0 &&
                    prevdata.map((val) => (
                      <div className="mt-4 col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                        <Charcard
                          product={val}
                          type={"preview"}
                        />
                      </div>
                    ))
                  }


                </div>

                :
                <>
                 { NFTFormValue?.Category == "Quest" &&
                 <> <div>
                    <p className='upload_title'>Upload</p>
                    <p className='upload_type'>PNG, GIF or WEBP Max 50mb.</p>

                  </div>
                  <div className='col-lg-6 leftsec'>

                    <div className='upload_frame' onClick={handleFileUploadClick} style={{ cursor: 'pointer' }}>
                      <input
                        type="file"
                        id="NFTOrginalImage"
                        multiple
                        webkitdirectory="true"
                        style={{ display: 'none' }}
                        onChange={(e) => onChange(e, 'Orginal')}
                      />
                      <img src={file} className='img-fluid file_upload_img' width={100} alt='file' />
                      <p className='first'>Upload Files</p>
                      <p className='second'>Upload Your Folder</p>
                      <p className='third'>PNG, GIF or Webp Maximum file size 50mb</p>
                      {ValidateError.NFTOrginalImage && (
                        <span className="text-danger img-file">
                          {ValidateError.NFTOrginalImage}
                        </span>
                      )}
                    </div>
                  </div>
                  </>}
                  <div className={`col-lg-6 form_sec`}>
                    <div>
                      <label for="exampleInputEmail1">Category</label>
                      <Select
                        className='react_sel'
                        onChange={(e) => {
                          setNFTFormValue({
                            ...NFTFormValue,
                            ...{ ["Category"]: e.label },
                          })
                          if (e.label == 'Equipments') {
                            setNFTFormValue({
                              ...NFTFormValue,
                              ...{ ["CoinName"]: currency.filter(val => val.label != "BNB" && val.label != "ETH")[0].label, ["Category"]: e.label },
                            })
                          }
                        }}
                        value={
                          {
                            label: NFTFormValue.Category ? NFTFormValue.Category : "Please Select Category",
                            value: NFTFormValue.Category ? NFTFormValue.Category : "Please Select Category",
                          }
                        }
                        options={
                          category
                        }
                        classNamePrefix="react-select"
                        isSearchable={false}
                      />
                      {ValidateError?.Category && (
                        <span className="text-danger img-file">
                          {ValidateError?.Category}
                        </span>
                      )}
                    </div>
                    {NFTFormValue.Category != "Quest" && <div className='row mb-4'>
                      <form>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Price</label>
                          <input type="text" class="form-control" id="NFTPrice"
                            onChange={onChange}
                            maxLength={7}
                            value={NFTFormValue.NFTPrice} aria-describedby="emailHelp" placeholder="Price Of The NFT" />
                          <Select
                            className='react_sel mt-3'
                            onChange={(e) => {

                              if (NFTFormValue.Category == "Character" || NFTFormValue.Category == "") {
                                setNFTFormValue({
                                  ...NFTFormValue,
                                  ...{ ["CoinName"]: e.label }
                                })
                              }
                            }
                            }
                            value={
                              {
                                label: NFTFormValue?.Category == "Character" ?
                                  NFTFormValue.CoinName ? NFTFormValue.CoinName : "Please Select Coin"
                                  :
                                  currency.filter(val => val?.label != "BNB" && val?.label != "ETH")?.[0]?.label

                                ,
                                value: NFTFormValue.Category == "Character" ?
                                  NFTFormValue.CoinName ? NFTFormValue.CoinName : "Please Select Coin"
                                  :
                                  currency.filter(val => val?.label != "BNB" && val?.label != "ETH")?.[0]?.label

                              }
                            }
                            options={
                              currency
                            }
                            classNamePrefix="react-select"
                            isSearchable={false}
                            disabled={false}
                          />
                          {ValidateError.NFTPrice && (
                            <span className="text-danger img-file">
                              {ValidateError.NFTPrice}
                            </span>
                          )}
                          {ValidateError.CoinName && (
                            <span className="text-danger img-file coin">
                              {ValidateError.CoinName}
                            </span>
                          )}

                        </div>
                      </form>
                    </div>}

              {/* This is for Json file */}
              <div className='row mb-4 upload_row mt-3'>
                      <div className='col-9'>
                        <form>
                          <div class="form-group">
                            <label for="exampleInputEmail1">Upload {NFTFormValue?.Category != 'Quest' ? "JSON" : "Excel"} File</label>
                            <input type="text" class="form-control" value={filename} id="exampleInputEmail1" aria-describedby="emailHelp" disabled={true} placeholder="" />
                            {ValidateError.jsonfile && (
                              <span className="text-danger img-file">
                                {ValidateError.jsonfile}
                              </span>
                            )}
                          </div>
                        </form>
                      </div>
                      <div className='col-3'>
                        <div class="loadmore_btn_sec">
                          <input type="file"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            accept=".json" class="file" onChange={(e) => onChange(e, NFTFormValue?.Category == 'Quest' ? "excel" : "json")} />
                          <button className='btn btn-minting' onClick={() => handleUpload()}>Choose</button>
                        </div>
                      </div>
                    </div>

                    {NFTFormValue.Category != "Quest" && <div className='row mb-4'>
                      <form>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Set royalty %</label>
                          <input type="number" onChange={onChange} id="NFTRoyalty" value={NFTFormValue.NFTRoyalty} class="form-control" aria-describedby="emailHelp" placeholder="0" />
                        </div>
                        {ValidateError.NFTRoyalty && (
                          <span className="text-danger img-file">
                            {ValidateError.NFTRoyalty}
                          </span>
                        )}
                      </form>
                    </div>}
                  </div>
                </>
              }
            </div>
            {prev ? <></> :
              <div className='btn_sec'>
                <button type='button' className='btn btn-minting' onClick={() => NFTFormValue.Category == "Quest" ? excelread() : imageUpload()}> {MintButton == "stop" && "Start"}
                  {MintButton == "start" && "Start Minting"}
                  {MintButton == "process" && "In-Progress"}
                  {MintButton == "try" && "Try-Again"}
                  {MintButton == "done" && "Minted"}</button>
              </div>
            }
          </div>
        </div>



      </div>


      <Footer />


    </div>
  )
}
