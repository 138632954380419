import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BiLogoFacebook } from 'react-icons/bi';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';
import { getSocialData } from '../actions/axios/useaxios'

export default function Footer() {
  var [link, setLink] = useState([]);
  useEffect(() => {
    Getlink()
  }, [])
  const Getlink = async () => {

    let link_res = await getSocialData();
    console.log("link_res",link_res)
    setLink(link_res?.data ?? [])
  }
  return (
    <div>
      <div className='footer'>
        <div className='container inner_container'>
          <div className='row footer_row_new'>
            <div className='col-lg-6 col-xl-5 left_sec mb-0'>
              <p>Copyright © 2023 All Right Reserved Savagecrown</p>
            </div>
            <div className='col-lg-4 center_sec'>
              <div className='center_sec_list'>
                <Link to="/terms">Terms of Sevices </Link> | <Link to='/privacy'>Privacy Policy </Link>
              </div>
            </div>
            <div className='col-lg-2 col-xl-3 end_sec'>
              <div className='social_icons'>
                <ul className='footer_social_list mb-0 ps-0'>
                  {link?.map((mlink) => {
                    return (
                      <>
                        {
                          (mlink.website).toLowerCase() === "facebook" &&
                          <a href={mlink.link} target="blank"><BiLogoFacebook /></a>}
                      
                        {
                          (mlink.website).toLowerCase() === "instagram" &&
                          <a href={mlink.link} target="blank"> <AiOutlineInstagram /></a>}
                        
                        {
                          (mlink.website).toLowerCase() === "twitter" &&
                          <a href={mlink.link} target="blank" className='ms-2'> <AiOutlineTwitter /></a>}
                        
                        {/* {
                          (mlink.website).toLowerCase() === "discord" &&
                          <a href={mlink.link} target="blank" className='ms-2'> <AiOutlineTwitter /></a>} */}

                      </>
                    )
                  })}
                </ul>
              </div>
            </div>

          </div>
        </div>


      </div>



    </div>
  )
}
