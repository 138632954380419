import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import coin from '../assets/images/coin.png'
import CharCard from '../components/charcard';
import { useSelector } from 'react-redux'
import inner_line from '../assets/images/inner_line.png'
import Mintingmodal from '../components/modal/mintingmodal';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { toast } from "react-toastify";
import config from '../components/config/config'
//axios
import { userRegister } from '../actions/axios/useaxios';
import { Token_List_Func } from '../actions/axios/nftaxios';
import HeaderOne from '../layout/HeaderOne';
import Nodata from '../components/nodata';
import { isEmpty } from '../actions/common';

export default function Armory() {
  const [mintingModal, setMintingModal] = useState(false);
  const navigate = useNavigate()

  const { accountAddress } = useSelector(state => state.LoginReducer.AccountDetails)
  const { AdminAddress } = useSelector((state) => state.LoginReducer);
  const [Tokens, SetTokens] = useState({ 'folder': { 'loader': true, page: 1, list: [], loadmore: false } })
  const [userProfile, setUserProfile] = useState({})
  const { isStake, balance ,Settings } = useSelector((state) => state.LoginReducer);
  const [buydetail, setBuydetail] = useState({});
  const [TabName, SetTabName] = useState(AdminAddress == accountAddress ? "folder" : "character");
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const [loadingstate, setLoadingstate] = useState(true)
  const [page, setPage] = useState(1)
  const [loadhide, setloadhide] = useState(true)

  useEffect(() => {
    if (typeof Tokens[TabName] == 'undefined') {
      console.log("ENNAPANNA", Tokens[TabName])
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, TabName);
    }
  }, [TabName, userProfile, isStake,accountAddress])

  useEffect(()=>{
    if(!isEmpty(Settings) && !Settings?.walletsection){
      navigate('/')
    }
  },[Settings])


  useEffect(() => {
    if (payload) {
      Tokens[TabName] = { page: 1, list: [], loader: false, loadmore: false };
      SetTokens(Tokens);
      Explore();
      getProfileDetails();
    }
  }, [payload, isStake])


  const Explore = async (data, tab) => {
    setLoadingstate(false)
    let  page = data ? data : (Tokens[TabName]?.page)
    let  SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 12,
      CustomUrl: payload?.CustomUrl,
      NFTOwner: payload?.WalletAddress,
      page: page ?? 1,
      from: 'myItem',
      admin: (accountAddress == AdminAddress),
      status: (TabName == "sale" || TabName == "character") ? "sale" : "later"
    }
    let Resp = await Token_List_Func(SendDATA)
    if (Resp?.status && Resp?.data?.length > 0) {
      SetTokens({
        ...Tokens, ...{
          [TabName]: {
            list: SendDATA.page == 1 ? Resp?.data : [...Tokens[TabName]?.list, ...Resp?.data],
            page: Tokens[TabName]?.page + 1,
            loadmore: true
          }
        }
      })
      setLoadingstate(false)
    }
    else {
      SetTokens({
        ...Tokens, ...{
          [TabName]: {
            list: SendDATA.page == 1 ? Resp?.data : [...Tokens[TabName]?.list, ...Resp?.data],
            page: Tokens[TabName]?.page +1,
            loadmore: false
          }
        }
      })
    }

  }

  const getProfileDetails = async () => {
    try {
      let  SendDATA = {
        CustomUrl: payload?.CustomUrl,
        Type: 'getProfile',
        User: payload?.WalletAddress
      }

      let  profileInfo = await userRegister(SendDATA)
      if (profileInfo?.success == 'success' && profileInfo?.data?.WalletAddress) {
        setUserProfile(profileInfo.data)
      }
      else {
        const id = toast.loading("Searching User");
        toast.update(id, { render: 'User Not found', type: 'error', isLoading: false, autoClose: 700, closeButton: true, closeOnClick: true })
        setTimeout(function () {
          navigate('/')
        }, 2000);
      }
    }
    catch (err) {
      console.log('erin getprofile', err)
    }
  }



  const LoadMore = () => {
    setLoadingstate(true)
    // Tokens[TabName].page = Tokens[TabName].page + 1;
    // SetTokens(Tokens);
    Explore(Tokens[TabName].page);
    setLoadingstate(false)

  }

  return (
    <div className='inner_pages'>
      <HeaderOne />
      <div className='quest_pages'>
        <div className='inner_sec'>
          <div className='container inner_container'>
            <div className='top_sec'>
              <div className='row'>
                <div className='text-center mb-4'>
                  <p className='page_title imfell_font'>Armory</p>
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
                </div>
                <div className='desc_sec'>
                  <p className='page_desc averia_font'>View your collection of Savage Crown NFTs</p>
                  <div className='coin_sec'>
                    <p className='balance_coin'><img src={coin} alt='coin' className='img-fluid' width={25} /> x {balance}</p>
                  </div>
                </div>
              </div>

            </div>
            <div className='tab_sec'>
              <Tabs
                defaultActiveKey={AdminAddress == accountAddress ? "folder" : "character"}
                id="uncontrolled-tab-example"
                className="tabs_design mb-3"
                onSelect={(e) => SetTabName(e)}
              >
               {AdminAddress == accountAddress &&  <Tab eventKey="folder" title="FOLDERS">

                  <div className='row'>
                    {loadingstate ?

                      <>
                        <div className="text-center">
                          <h3 className='loading_text'>Loading...</h3>
                        </div>
                      </>
                      :


                      Tokens[TabName] &&
                        Tokens[TabName]?.list?.length > 0 ?
                        Tokens[TabName].list.map((val, index) =>
                      <>
                        <div
                          className="mt-4 col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                          <Link style={{ textDecoration: 'none' }} to="/dropdetail" state={{id : val?._id , qty : val?.DropNFTs?.length , price : val?.Dropprice , coin : val?.Dropcoin,index : index}}>
                            <CharCard
                              from={'folder'}
                              product={val}
                              type={"folder"}
                            index={index}
                            />
                          </Link>
                        </div>
                      </>
                      ) : <>
                        <Nodata/>

                      </>
                    }


                  </div>
                  {Tokens[TabName]?.loadmore && <div className='loadmore_btn_sec mt-4'>
                    <button className='btn loadmore_btn' alt="loadmore" onClick={() => LoadMore()}>Load More</button>
                  </div>}

                </Tab>}
               {AdminAddress != accountAddress &&  <Tab eventKey="character" title="CHARACTERS">

                  <div className='row'>
                    {loadingstate ?

                      <>
                        <div className="text-center">
                          <h3 className='loading_text'>Loading...</h3>
                        </div>
                      </>
                      :


                      Tokens[TabName] &&
                        Tokens[TabName]?.list?.length > 0 ?
                        Tokens[TabName].list.map((val, index) =>
                          <>
                            <div
                              className="mt-4 col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                              <CharCard
                                from={'marketplace'}
                                product={val}
                                type={TabName}
                                index={index}
                              />
                            </div>
                          </>
                        ) : <>
                          <Nodata />

                        </>}


                  </div>
                  {Tokens[TabName]?.loadmore && <div className='loadmore_btn_sec mt-4'>
                    <button className='btn loadmore_btn' alt="loadmore" onClick={() => LoadMore()}>Load More</button>
                  </div>}

                </Tab>}
                <Tab eventKey="equipments" title="EQUIPMENT">
                  <div className='row'>
                    {loadingstate ?

                      <>
                        <div className="text-center">
                          <h3 className='loading_text'>Loading...</h3>
                        </div>
                      </>
                      :
                      Tokens[TabName] &&
                        Tokens[TabName]?.list?.length > 0 ?
                        Tokens[TabName].list.map((val, index) =>
                          <>
                            <div
                              className="mt-4 col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
                              <CharCard
                                from={'marketplace'}
                                product={val}
                                type={TabName}
                                index={index}

                              />
                            </div>


                          </>
                        ) : <>
                          <Nodata />
                        </>}
                  </div>
                  {Tokens[TabName]?.loadmore && <div className='loadmore_btn_sec mt-4'>
                    <button className='btn loadmore_btn' alt="loadmore" onClick={() => LoadMore()}>Load More</button>
                  </div>}
                </Tab>
              </Tabs>
            </div>
          </div>


        </div>
      </div>


      <Footer />
      {buydetail && <Mintingmodal
        imgbuy={buydetail.char_img}
        show={mintingModal}
        onHide={() => setMintingModal(false)}
        buymodal
      />
      }
    </div>
  )
}
