import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import inner_line from '../../assets/images/inner_line.png'
import config from '../config/config'
import { toast } from 'react-toastify';
import useContractProviderHook from '../../actions/contractProviderHook'
import { useSelector, useDispatch } from "react-redux";
import { Enablestake, Revealnft } from '../../actions/axios/nftaxios';
import { Token_List_Func } from '../../actions/axios/nftaxios';
import CharCard from '../charcard';
import Nodata from '../nodata';

export default function Questselect(props) {
  const questdata = props?.questdata
  const [Tokens, SetTokens] = useState({ 'character': { 'loader': true, page: 1, list: [], loadmore: false } })
  const [TabName, SetTabName] = useState(props?.category);
  const [loadingstate, setLoadingstate] = useState(true)
  const { payload } = useSelector(state => state.LoginReducer.User)
  useEffect(() => {
    if(payload){
      Tokens[TabName] = { page: 1, list: [], loader: false, loadmore: false };
      SetTokens(Tokens);
      Explore();
    }
  }, [payload])
  const Explore = async (data) => {
    setLoadingstate(false)
    let  page = (Tokens[TabName]?.page)
    let  SendDATA = {
      TabName: TabName,
      limit: 6,
      CustomUrl: payload?.CustomUrl,
      NFTOwner: payload?.WalletAddress,
      page: page ?? 1,
      from: 'myItem',
      except: true,
      attributes : JSON.stringify(questdata?.requiretype)
    }
    let Resp = await Token_List_Func(SendDATA)
    console.log('RespRespRespResp-->',Resp)
    if (Resp?.status && Resp?.data?.length > 0) {
      SetTokens({
        ...Tokens, ...{
          [TabName]: {
            list: SendDATA.page == 1 ? Resp?.data : [...Tokens[TabName]?.list, ...Resp?.data],
            page: Tokens[TabName]?.page,
            loadmore: true
          }
        }
      })
      setLoadingstate(false)
    }
    else {
      SetTokens({
        ...Tokens, ...{
          [TabName]: {
            list: SendDATA.page == 1 ? Resp?.data : [...Tokens[TabName]?.list, ...Resp?.data],
            page: Tokens[TabName]?.page,
            loadmore: false
          }
        }
      })
    }

  }
  const LoadMore = () => {
    setLoadingstate(true)
    Tokens[TabName].page = Tokens[TabName].page + 1;
    SetTokens(Tokens);
    Explore(Tokens[TabName].page);
    setLoadingstate(false)

  }

  return (
    <>
      <div >
        <Modal  {...props} centered size="xl" className='revealmodal'>
          <div className='bg_pattern'></div>
          <div className='close_btn'>
            <p className='close_text' onClick={props.onHide}>X</p>
          </div>
          <Modal.Header >
            <Modal.Title><p>Your Characters</p>
              <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='btn_sec krbtn_sec'>
              {loadingstate ?

                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                  </div>
                </>
                :


                Tokens[TabName] &&
                  Tokens[TabName]?.list?.length > 0 ?
                  <>
                  <div className='row'>
                    {Tokens[TabName].list.filter((item) => {
                      if (!props.NFTsid.includes(item.NFTId)) {
                        return (item)
                      }
                    }).map((val, index) =>

                      <div
                        className="col-xl-4 col-lg-6 col-md-12 col-sm-12 tf-loadmore 3d cyber" onClick={() => props.onSelect(val, props.index)}>
                        <CharCard
                          from={'marketplace'}
                          product={val}
                          type={TabName}
                          index={index}
                        />
                      </div>

                    )}
                    {Tokens[TabName]?.loadmore && <div className='loadmore_btn_sec text-center'>
                      <button className='btn loadmore_btn' alt="loadmore" onClick={() => LoadMore()}>Load More</button>
                    </div>}
                    </div>
                    
                  </>
                  : <>
                     <Nodata/>

                  </>
                  
              }
            </div></Modal.Body>

        </Modal>
      </div>



    </>


  )
}
