import React, { useState, useMemo, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import mining_img from '../../assets/images/char_img.png'
import config from '../config/config'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useContractProviderHook from "../../actions/contractProviderHook";
import { buyipfsandupdate, Buymint } from '../../actions/axios/nftaxios';
import { isEmpty } from '../../actions/common';

import { toast } from 'react-toastify';



export default function Mintingmodal(props) {
  const [count, setCount] = useState(0);
  const dispatch = useDispatch()
  const product = props?.product
  const data = props?.data
  const increaseCount = () => {
    if (count < (product.remaining ?? product.NFTBalance)) {
      setCount(count + 1);
    }
  };


  const decreaseCount = () => {
    if (count != 0) {
      setCount(count - 1);
    }

  };


  useEffect(() => {
    if (product?.CoinName == "BNB") {
      SetBtn('done')
      SetApp_Btn('start')
    }
    else {
      SetBtn('start')
      SetApp_Btn('init')
    }

  }, [count])

  const ContractCall = useContractProviderHook();

  const [NFTQuantity, SetNFTQuantity] = useState("1")
  const [TokenBalance, SetTokenBalance] = useState('0')
  const [Error, SetError] = useState('')
  const [Btn, SetBtn] = useState('start')
  const [App_Btn, SetApp_Btn] = useState(product?.CoinName != "BNB" ? 'init' : "start")

  const { currency } = useSelector(state => state.LoginReducer)
  const { web3, web3p, accountAddress, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
  const { payload } = useSelector(state => state.LoginReducer.User)
  const { isStake } = useSelector((state) => state.LoginReducer);


  const decimal = currency?.filter(item => item.label === product?.CoinName)?.pop()?.decimal ?? 18
  const token_address = currency?.filter(item => item.label === product?.CoinName)?.pop()?.address ?? config.DEADADDRESS
  const YouWillGet = useMemo(() => {
    if (product?.NFTPrice && NFTQuantity)
      return ContractCall.buy_bid_price_calculation((product?.NFTPrice * count).toString(), decimal.toString())
  }, [NFTQuantity, props, count])
  const Validation = async () => {
    let  error = {};
    if (isEmpty(NFTQuantity)) return "Token Quantity Required"
    else if (product.NFTBalance < NFTQuantity) return error.NFTQuantity = "NFT Quantity should be less than " + product.NFTBalance
    if (product?.CoinName != "BNB" && ((product?.TokenPrice * NFTQuantity) > TokenBalance)) return "Insufficient Balance"
    else return ContractCall.Contract_Base_Validation()
  }
  useEffect(() => {
    if (product?.CoinName == "BNB") {
      SetBtn('done')
      SetApp_Btn('start')
    }
  }, [product])
  const FormSubmit = async () => {
    if (accountAddress) {
      const id = toast.loading('Purchasing Token on processing')
      SetError('')
      SetBtn('process')
      let  error = await Validation()
      if (error) {
        toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
        SetBtn('error')
        SetError(error)
      }
      else {
        if (!props.buymodal) {
          let  value = await ContractCall.buy_bid_price_calculation((data[0]?.nfts.NFTPrice * count).toFixed(6).toString(), decimal.toString())
          let cont = await ContractCall.approve_721_1155(token_address, (data[0]?.nfts?.ContractType == "721" || data[0]?.nfts?.ContractType == 721) ? config.ERC721 : config.ERC1155, web3p?.utils.toWei(value.toString()))
          if (cont) {
            toast.update(id, { render: 'Approve For Token Successfully', type: 'success', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
            SetBtn('done')
            SetApp_Btn('start')
          }
          else {
            toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
            SetBtn('try')
          }
        }
        else {
          let bal = await ContractCall.Token_Balance_Calculation(token_address, accountAddress)
          if ((bal >= YouWillGet)) {
            let cont = await ContractCall.approve_721_1155(token_address, (product?.ContractType == "721" || product?.ContractType == 721) ? config.ERC721 : config.ERC1155, web3p?.utils.toWei(YouWillGet.toString()))
            if (cont) {
              toast.update(id, { render: 'Approve For Token Successfully', type: 'success', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
              SetBtn("done")
              SetApp_Btn('start')
            }
            else {
              toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
              SetBtn('try')
            }
          }
          else {
            toast.update(id, { render: `You don't have balance in your wallet`, type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
          }

        }


      }
    }
    else {
      toast.error("Please connect Wallet")
    }
  }
  const ipfshash = async (id) => {
    let  send_data = {
      NFTId: id ? id : [product?.NFTId]
    }
    let  Resp = await buyipfsandupdate(send_data)
    if (Resp.status) return Resp
    return ({ success: false })
  }

  const mint = async () => {
    if (accountAddress) {
      const id = toast.loading('Purchasing Token on processing')
      try {
        if (accountAddress) {
          if (!props?.buymodal) {
            let value = await ContractCall.buy_bid_price_calculation((data[0]?.nfts.NFTPrice * count).toFixed(6).toString(), decimal.toString())
            let ids = await Promise.all(data?.map(item => item?.nfts?.NFTId).slice(0, count))
            let ipfsadd = await ipfshash(ids)
            let ipfs = await Promise.all(ipfsadd?.MetaData?.map(item => config.IPFS + item))
            let hash = await ContractCall.lazyminting_721_1155(
              count,
              data[0]?.nfts?.ContractType,
              data[0]?.nfts.CoinName,
              web3.utils.toWei(value.toString()),
              ipfs,
              [config.IPFS + String(data[0]?.Dummyipfs), (data[0]?.nfts.CoinName == "BNB" || data[0]?.nfts.CoinName == "ETH") ? "Coin" : data[0]?.nfts.CoinName, data[0]?.nfts.Randomname],
              accountAddress,
              [
                web3?.utils.toWei(data[0]?.nfts.NFTRoyalty),
                web3.utils.toWei(`${(Number(product?.NFTPrice) * Number(count)).toFixed(6)}`).toString(),
                data[0]?.nfts.Nonce,
                web3.utils.toWei(data[0]?.nfts.NFTPrice.toString()),
              ],
              data[0]?.nfts.Hash
            )
            if (hash.status) {
              let NFTobj = {}
              await Promise.all(ids.map((val, i) => {
                NFTobj[val] = hash?.Tokenid[i]
              }))
              let update = {
                type: data[0]?.nfts?.ContractType,
                NFTOwner: accountAddress,
                NFTobj,
                total: data[0]?.nfts?.NFTQuantity,
                HashValue: hash.HashValue,
                bal: data[0]?.nfts.NFTBalance,
                quantity: "1",
                type: data[0]?.nfts?.ContractType
              }
              let Resp = await Buymint(update)
              if (Resp.status) {
                setTimeout(() => {
                  toast.update(id, { render: 'Token Purchased Successfully', type: 'success', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                }, 1000);
                SetApp_Btn('done')
                dispatch({
                  type: "Stake_Section",
                  Stake_Section: {
                    isStake: !isStake
                  }
                })
                setCount(0)
                props.onHide()
              }
              else {
                setTimeout(() => {
                  toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                }, 1000);
                SetApp_Btn('try')
              }
            }
            else {
              setTimeout(() => {
                toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
              }, 1000);
              SetApp_Btn('try')
            }

          }
          else {
            let  hash;
            if (!product?.Minted) {
              let  ipfsadd = await ipfshash()
              let  ipfs = await Promise.all(ipfsadd?.MetaData?.map(item => config.IPFS + item))

              hash = await ContractCall.lazyminting_721_1155(
                "",
                product?.ContractType,
                product.CoinName,
                web3.utils.toWei(YouWillGet.toString()),
                [...ipfs, (product?.CoinName == "BNB" || product?.CoinName == "ETH") ? "COIN" : product?.CoinName, product?.Randomname],
                [
                  web3?.utils.toWei(product.NFTRoyalty).toString(),
                  web3.utils.toWei(`${(Number(product?.NFTPrice) * Number(count)).toFixed(6)}`).toString(),
                  product?.Nonce,
                  web3.utils.toWei(product?.NFTPrice).toString(),
                  String(product?.NFTQuantity),
                  String(count),
                  web3.utils.toWei(`${(Number(product?.NFTPrice) * Number(count)).toFixed(6)}`).toString()
                ],
                accountAddress,
                product?.Hash
              );

            }
            else {
              hash = await ContractCall.buy_721_1155(
                product?.CoinName,
                [product?.NFTId, web3.utils.toWei(`${(Number(product?.NFTPrice) * Number(count)).toFixed(6)}`).toString(), count],
                (product?.CoinName == "BNB" || product?.CoinName == "ETH") ? "COIN" : product?.CoinName
              );


            }
            if (hash?.status) {
              let NFTobj = {}
              await Promise.all([product?.NFTId].map((val, i) => {
                NFTobj[val] = hash?.Tokenid?.[i]
              }))
              let update = {
                NFTOwner: accountAddress,
                NFTobj,
                HashValue: hash.HashValue,
                tokens: payload?.Savagetokens,
                total: product?.NFTQuantity,
                quantity: count,
                bal: product?.NFTBalance,
                type: product?.ContractType
              }
              let Resp = await Buymint(update)
              if (Resp.status) {
                toast.update(id, { render: 'Token Purchased Successfully', type: 'success', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                SetApp_Btn('done')
                dispatch({
                  type: "Stake_Section",
                  Stake_Section: {
                    isStake: !isStake
                  }
                })
                setCount(0)
                props.onHide()
              }
              else {
                toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                SetApp_Btn('try')
              }
            }
            else {
              toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
              SetApp_Btn('try')
            }

          }

        } else {
          toast.update(id, { render: 'Please Connect Wallet', type: 'warning', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
          SetApp_Btn('try')
        }
      }
      catch (err) {
        toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
        SetApp_Btn('try')
        console.error(err)
      }
    }
    else {
      toast.error("Please connect Wallet")
    }
  }

  return (
    <div >
      <Modal  {...props} centered size="md" className='mintingmodal'>
        <div className='bg_pattern'></div>
        <div className='close_btn'>
          <p className='close_text' onClick={() => { props.onHide(); setCount(0); }}>X</p>
        </div>
        <Modal.Header >
          <Modal.Title><p>COLLECT YOUR NFT</p>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='text-center'>
            {product?.CompressedFile ? <img src={`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`} alt='create_nft' className='img-fluid mining_nft_img' /> : <img src={mining_img} alt='create_nft' className='img-fluid mining_nft_img' />}

          </div>
          <div className='detail_sec'>
            {props.buymodal ?
              <div className='lists'>
                <p className='label'>Remaining</p>
                <p>{product?.NFTBalance ? product?.NFTBalance : '0'}/{product?.NFTQuantity ? product?.NFTQuantity : '0'}</p>

              </div> : <div className='lists'>
                <p className='label'>Remaining</p>
                <p>{product?.remaining ? product?.remaining : '0'}/{product?.total ? product?.total : '0'}</p>

              </div>}
            <div className='lists'>
              <p className='label'>price</p>
              <p className='range'>
                {product?.NFTPrice ? product?.NFTPrice : "0"} {product?.CoinName && product?.CoinName}
              </p>
            </div>
            <div className='lists'>
              <p className='label'>Quantity</p>
              <div className='counter_sec'>
                <>
               {(count != 0 ) ?  <p className='count_btn' onClick={decreaseCount}>-</p> : <p className='count_btn' >{" "}</p> } 
               </>
                <p>{count}</p>
                <>
               {(count < (product?.remaining ?? product?.NFTBalance)) ?  <p className='count_btn' onClick={increaseCount}>+</p> : <p className='count_btn' >{" "}</p>} 
               </>
              </div>
              <p className='range'>
                {(count * (product?.NFTPrice ? product?.NFTPrice : 0)).toFixed(6)}  {product?.CoinName}
              </p>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <div className='btn_sec'>

            <div className='buttonappbuy'>
              {props.buymodal ?

                <Button className="btn banner_btn appbuy" tabIndex="-1"
                  disabled={Btn == 'error' || Btn === "process" || Btn == "done" || count == 0 ? true : false}
                  onClick={Btn == 'start' || Btn === "try" ? FormSubmit : null}>

                  {
                    Btn == 'start' && 'Approve'
                    || Btn == 'try' && 'Try-Again'
                    || Btn == 'error' && 'Error'
                    || Btn == 'done' && 'Done'
                    || Btn == 'process' && 'In-Progress'
                  }
                </Button>

                :
                <>
                  {product?.CoinName != "BNB" && <Button className="btn banner_btn appbuy" tabIndex="-1"
                    disabled={Btn == 'error' || Btn === "process" || Btn == "done" || count == 0 ? true : false}
                    onClick={Btn == 'start' || Btn === "try" ? FormSubmit : null}>

                    {
                      Btn == 'start' && 'Approve'
                      || Btn == 'try' && 'Try-Again'
                      || Btn == 'error' && 'Error'
                      || Btn == 'done' && 'Done'
                      || Btn == 'process' && 'In-Progress'
                    }
                  </Button>}
                </>

              }


              {props.buymodal ?
                <Button className="btn banner_btn appbuy ms-2 "
                  tabIndex="-1"
                  disabled={App_Btn == 'init' || App_Btn == 'error' || App_Btn === "process" || App_Btn === "done" ? true : false}
                  onClick={App_Btn == 'start' || App_Btn === "try" ? mint : null}>{App_Btn == 'start' && 'BUY NOW'
                    || App_Btn == 'try' && 'Try-Again'
                    || App_Btn == 'error' && 'Error'
                    || App_Btn == 'done' && 'Done'
                    || App_Btn == 'process' && 'In-Progress'
                    || App_Btn == 'init' && 'Proceed to payment'
                  }</Button>
                :
                <>
                  {<Button className="btn banner_btn appbuy ms-2 "
                    tabIndex="-1"
                    disabled={App_Btn == 'init' || App_Btn == 'error' || App_Btn === "process" || App_Btn === "done" || count == 0 ? true : false}
                    onClick={App_Btn == 'start' || App_Btn === "try" ? mint : null}>{App_Btn == 'start' && 'BUY NOW'
                      || App_Btn == 'try' && 'Try-Again'
                      || App_Btn == 'error' && 'Error'
                      || App_Btn == 'done' && 'Done'
                      || App_Btn == 'process' && 'In-Progress'
                      || App_Btn == 'init' && 'Proceed to payment'
                    }</Button>}
                </>
              }
            </div>
          </div>
          <div className='bg_pattern_bottom'></div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
