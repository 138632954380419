import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom"
import { Dropdown, Navbar, Container, NavDropdown, Nav } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Connectmodal from '../components/modal/connectwalletmodal';
import brand_logo from '../assets/images/logo_header.png'
import { FaTwitter, FaDiscord, FaFacebook, FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { connectWallet, getServiceFees } from '../hooks/usewallet';
import { isEmpty, Name_showing } from '../actions/common';
import { getSettings, getSocialData } from '../actions/axios/useaxios'
import { userRegister, SearchAction, GetUserCookieToken } from '../actions/axios/useaxios'
import { Dropdetail } from '../actions/axios/nftaxios'
import { Category, Currency, USDPRICE, TOKENPRICE } from '../actions/axios/cms.axios'
import useContractProviderHook from "../actions/contractProviderHook";
import Mintingmodal from '../components/modal/mintingmodal';
import config from '../components/config/config'
export default function Header() {
  const loadermain = useRef(null);
  var [link, setLink] = useState([]);
  const ContractCall = useContractProviderHook();
  const [connectModal, setConnectModal] = useState(false);
  const [dropdata, setDropdata] = useState([]);
  const [dropdetails, setDropdetails] = useState({})
  const push = useNavigate()
  const wallet = useSelector(state => state.LoginReducer.AccountDetails);
  const { payload, token } = useSelector(state => state.LoginReducer.User)
  const { AdminAddress } = useSelector((state) => state.LoginReducer);
  const { isStake, Settings } = useSelector((state) => state.LoginReducer);
  const [banner, setBanner] = useState(true);
  const [hovering, setHovering] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("walletConnectType") && wallet.accountAddress == '' && Settings?.walletsection) {
      initialConnectWallet(localStorage.getItem("walletConnectType"))
    }
  }, [isStake]);

  useEffect(() => {
    if (window.ethereum && Settings?.walletsection) {
      window.ethereum?.on("accountsChanged", handleAccountChange);
      return () => {
        window.ethereum?.removeListener("accountsChanged", handleAccountChange);
      };
    }
    Getlink()
  }, [])

  useEffect(() => {
    if (wallet.web3 && Settings?.walletsection) {
      CurrencyList()
    }
  }, [wallet])






  const [show, setShow] = useState(true)


  useEffect(() => {
    if (token && Settings?.walletsection) {
      getDrops(token)
    }
  }, [token, isStake])

  const handleAccountChange = (...args) => {
    initialConnectWallet(localStorage.walletConnectType);
  }

  const CurrencyList = async () => {
    let Resp = await Currency();

    Resp.msg = Resp?.msg.filter((val) => Number(val.ChainId) == config.CHAIN_ID)
    if (Resp?.msg) {
      let sen = [];
      await Promise.all(
        Resp?.msg[0]?.CurrencyDetails?.map(async (data) => {
          // console.log('kjdkgjkjsdfkg', data, !data.deleted)
          if (!data?.deleted) {
            if (data?.label == "BNB")
              var USD = await USDPRICE(data?.label);
            else var USD = await TOKENPRICE(data?.address);
            sen.push({
              value: data?.value,
              label: data?.label,
              address: data?.address,
              usd: USD ? USD : 0,
              decimal: data?.decimal,
            });
          }

        })
      );
      let token_address = sen?.filter(item => item.label != "BNB" && item.label != "ETH")?.pop()?.address
      let bal = await ContractCall.Token_Balance_Calculation(token_address, wallet.accountAddress)
      dispatch({
        type: "Register_Section",
        Register_Section: {
          currency: sen,
          balance: bal
        },
      });
    }
  };

  const [mintingModal, setMintingModal] = useState(false);

  const initialConnectWallet = async (type) => {

    const id = toast.loading(type + " Wallet Connecting...", { closeButton: true, autoClose: 1000 })

    let accountDetails = await connectWallet(type)
    if (!isEmpty(accountDetails)) {
      var resp = await setWalletAddress('InitialConnect', accountDetails.accountAddress, type)
      if (resp?.success == 'success') {
        toast.update(id, { render: resp.msg, type: resp.success, autoClose: 1000, isLoading: false, })
        dispatch({
          type: "Account_Section",
          Account_Section: { AccountDetails: accountDetails }
        })
      }

      else {
        toast.update(id, { render: "Connect Wallet", type: 'error', autoClose: 1000, isLoading: false, })
      }

    }
    else toast.update(id, { render: "Try Again", type: 'error', autoClose: 1000, isLoading: false, })
  }
  const setWalletAddress = async (type, walletAddress, walletType) => {
    if (walletAddress) {
      let NewMethod = {
        Type: type,
        WalletAddress: walletAddress,
        WalletType: walletType,
      };
      let Resp = await userRegister(NewMethod);
      if (Resp?.success == 'success') {
        dispatch({
          type: 'Register_Section',
          Register_Section: {
            User: {
              payload: Resp.data,
              token: Resp.token ? Resp.token : token
            }
          }
        })
        document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
        return Resp
      }
      else return Resp
    }
    else return { success: 'error', msg: 'No Address Detected.. Check Your Wallet' }

  }
  const walletDisconnect = async () => {
    localStorage.removeItem("accountInfo")
    localStorage.removeItem("walletConnectType")
    dispatch({
      type: 'Account_Section',
      Account_Section: {
        AccountDetails: {
          accountAddress: "",
          tokenBalance: 0,
          coinBalance: 0
        }
      }
    })
    push("/")
    document.cookie = 'token' + "=" + "" + ";" + ";path=/";
    GetUserCookieToken();
  }


  const getDrops = async (data) => {
    try {
      await GetUserCookieToken();
      let resp = await Dropdetail(data);
      if (resp?.status) {
        setDropdata(resp?.datas?.filter((item) => item.nfts?.Saled != true));
        setDropdetails({ NFTPrice: resp.price, CoinName: resp.coin, remaining: resp.count, total: resp?.datas?.length, Category: "character" })
      }
      else {
        setShow(resp?.status)
      }
    }
    catch (err) {
      console.log("droperr", err)
    }


  }

  useEffect(() => {
    getsettinglist()
  }, [])

  useEffect(() => {
    if (Settings?.walletsection) {
      getadminaddress()
    }
  }, [wallet, payload])

  const Getlink = async () => {
    let link_res = await getSocialData();
    console.log("sdfsdg", link_res);
    setLink(link_res?.msg ?? [])
  }


  const getadminaddress = async () => {
    var data = await ContractCall.Getadminaddress()
    dispatch({
      type: "Admin_Section",
      Admin_Section: {
        AdminAddress: String(data).toLowerCase(),
      },
    });
  }




  var scrollpos = window.scrollY;

  function add_class_on_scroll() {
    document.getElementById("navbar").classList.add("logo_hide");
    setBanner(false)
  }

  function remove_class_on_scroll() {
    document.getElementById("navbar").classList.add("logo_hide");
    setBanner(true)
  }




  const [scrollY, setScrollY] = useState(0);
  const [menuopen, setMenuopen] = useState(false);
  // Define a function to handle the scroll event
  var Widthwindow = window.innerWidth
  const handleScroll = () => {
    var scrollpos = window.scrollY;
    // Update the state with the current scroll position
    setScrollY(window.scrollY);
    if (Widthwindow < 992) {
      if (scrollpos > 200) {
        add_class_on_scroll();
      }
      else {
        remove_class_on_scroll();
      }
    };

    if (Widthwindow > 991) {
      if (scrollpos > 700) {
        add_class_on_scroll();
      }
      else {
        remove_class_on_scroll();
      }
    };
  }
  // Add the event listener when the component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getsettinglist()
  }, [])

  const getsettinglist = async () => {
    let resp = await getSettings()
    if (resp?.status) {
      dispatch({
        type: "Settings",
        Settings: {
          Settings: resp?.data
        }
      })
    }
  }

  const menuclick = () => {
    loadermain.current.classList.add("anims")
  }
  const handleMouseOver = () => {
    setHovering(true);
  }
  const handleMouseLeave = () => {
    setHovering(false);
  }
  return (
    <div id="navbar" className="wholes_header">
      <div className={menuopen ? 'header anims' : 'header'} >
        <Navbar expand="lg" className={banner ? 'bg-body-tertiary bannerstyle' : 'bg-body-tertiary'} >
          <Container>
            <Navbar.Brand className={banner ? 'd-none' : ''}>
              <Link to="/">
                <img src={brand_logo} width={200} height={90} alt='logo' className='img-fluid header_logo' />
                <img src={brand_logo} alt='logo' width={200} height={90} className='img-fluid  allside' />

              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setMenuopen(!menuopen)} />

            <Navbar.Collapse id="basic-navbar-nav" className="navbarcol">
              <Nav className="">
                <NavLink to="/">HOME</NavLink>
                {Settings?.walletsection &&
                  <>
                    <NavLink to="/store">STORE</NavLink>
                    <NavLink to="/quest">QUESTS</NavLink>
                    {wallet?.accountAddress && <NavLink to="/armory">ARMORY</NavLink>}
                    { AdminAddress && wallet?.accountAddress == AdminAddress && <NavLink to="/minting">CREATE</NavLink>}
                    {(wallet?.accountAddress != AdminAddress) && <div className="headermint" onClick={() => setMintingModal(true)}>MINT NOW</div>}
                  </>
                }

              </Nav>
              {link.length > 0   &&
                <div className={banner ? 'd-none' : 'social_icons'}>
                  <ul className='footer_social_list mb-1 footer_social_list_pl_alg'>
                    {link?.map((mlink) => {
                      return (
                        <>
                          {
                            (mlink.website).toLowerCase() === "twitter" &&
                            <a href={mlink.link} target="blank" className="ms-2"><FaTwitter /></a>}
                          {
                            (mlink.website).toLowerCase() === "discord" &&
                            <a href={mlink.link} target="blank"> <FaDiscord /></a>}
                          {
                            (mlink.website).toLowerCase() === "facebook" &&
                            <a href={mlink.link} target="blank"> <FaFacebookSquare /></a>}
                          {
                            (mlink.website).toLowerCase() === "instagram" &&
                            <a href={mlink.link} target="blank"> <FaInstagram /></a>}
                        </>
                      )
                    })}
                  </ul>
                </div>
              }
              {Settings?.walletsection && <div className="walletbtn_sec">
                <button className='btn connect_btn' onMouseEnter={handleMouseOver}
                  onMouseLeave={handleMouseLeave} onClick={() => { wallet && wallet.accountAddress ? walletDisconnect() : setConnectModal(true) }}>{wallet && wallet.accountAddress ? "Disconnect" : "Connect Wallet"}</button>
                {(hovering && wallet && wallet.accountAddress) && <div className="wallet_addre">
                  <p>{wallet.accountAddress}</p>
                </div>
                }
              </div>}

            </Navbar.Collapse>

          </Container>
        </Navbar>
      </div>
      <Connectmodal
        show={connectModal}
        onHide={() => setConnectModal(false)}
      />
      {dropdetails && <Mintingmodal
        show={mintingModal}
        type={'721'}
        onHide={() => setMintingModal(false)}
        product={dropdetails}
        data={dropdata}
      />}
    </div>
  )
}
