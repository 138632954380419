import { useSelector } from 'react-redux';
import Market from '../Abi/market.json'

import ERC721 from '../Abi/erc721.json'
import ERC1155 from '../Abi/erc1155.json'
import Token from '../Abi/erc20.json'
import Stake from '../Abi/stake.json'
import config from '../components/config/config'
import Web3 from 'web3';
import { isEmpty } from './common';


var web3s = new Web3(config.RPC_URL)


export default function useContractProviderHook() {
    const { accountAddress, web3, web3p, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
    const { sellerFees, buyerFees } = useSelector(state => state.LoginReducer.ServiceFees);

    const Contract_Base_Validation = () => {
        if (!web3) return 'Connect Your Wallet'
        if (!accountAddress) return 'Connect Your Wallet'
        if (!coinBalance) return "You Don't have Enough Balance"
        else return ''
    }

    const contrat_connection = async (...data) => {
        if (web3) {
            var contract_value = await new web3.eth.Contract(
                ...data
            );
            return contract_value;
        }
    }

    const EnableStake = async (...data) => {
        try {
            var ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            var contract_Method_Hash = await ConnectContract.methods.nftStack(...data).send({
                from: accountAddress
            })

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);


          let poollength = web3s.utils.hexToNumber(Number(receipt.logs[receipt.logs.length -1].topics[1]))
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
                poollength
            }
            return need_data;
        }
        catch (err) {
            console.log("EnableStake_err", err)
        }

    }

    const NFTWithdraw = async (...data) => {
        try {
            var ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            var contract_Method_Hash = await ConnectContract.methods.nftWithdraw(...data).send({
                from: accountAddress
            })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            let need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;
        }
        catch (err) {
            console.log("NFTWithdraw_err", err)
        }

    }

    const Getadminaddress = async () => {
        try {
            var ConnectContract = await contrat_connection(ERC721, config.ERC721)
            
            var owneradd = await ConnectContract.methods.owner().call();
            return owneradd;
        }
        catch (err) {
            console.log("Getadminaddress_er",err)
        }
    }

    const addpool = async (addpool) => {
        try {
            var ConnectContract = await contrat_connection(Stake, config.StakeAddress)
            let  contractobj = await
            ConnectContract.methods.editPool(...addpool)
                let  gasprice = await web3.eth.getGasPrice();
                let  gas_estimate = await contractobj.estimateGas({ from: accountAddress })          
            var contract_Method_Hash = await ConnectContract.methods.editPool(...addpool).send({
                from: accountAddress,
                gas: web3.utils.toHex(gas_estimate),
                        gasPrice:  web3.utils.toHex(gasprice)
            }).on('transactionHash', (transactionHash) => {
                return transactionHash
            });

            let  receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;

        }
        catch (err) {
            console.log('addpool_err-->',err)
            let  need_data = {
                status:false,
            }
            return need_data;
        }
    }

    const GetPoll = async () =>{
        try {
            let  ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            let  pooldata = await ConnectContract.methods.poolStatus(config.poolid).call()
            return pooldata;
        }catch(err){
            console.log("GetPoll_err",err)
        }
    }

    const Getdayreward = async (...data) => {
        try {
            let  ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            let  reward = await ConnectContract.methods.getDayReward(...data).call()
            return ({ success: true, reward });
        }
        catch (err) {
            console.log('Getdayreward_err-->',err)
            return ({ success: false });
        }
    }

    const Getreward = async (id,add,poolid,date) => {
        try {
            let  ConnectContract = await contrat_connection(Stake, config.StakeAddress);
            let  reward = await ConnectContract.methods.getReward(id,add,poolid,date).call()

            return ({ success: true, reward });
        }
        catch (err) {
            console.log('Getreward_err-->',err)
            return ({ success: false });
        }
    }

const getpooldata = async ()=>{
    try{
        let retdata=[];
        let  ConnectContract = await contrat_connection(Stake, config.StakeAddress);
        if(ConnectContract){
            let  poolcount = await ConnectContract.methods.poolCount().call()
            if(poolcount){
                for(let i=1 ; i <= Number(poolcount) ;  i++){
                    let  pooldata = await ConnectContract.methods.poolStatus(i).call()
                    let pushdata={ 
                        interval : pooldata?.interval/1e18,
                        lockPeriod : pooldata?.lockPeriod/1e18,
                        rewardPer  : pooldata?.rewardPer/1e18,
                        rewardToken : pooldata?.rewardToken
                    }
                    retdata.push(pushdata)
                }
            }
        }
        return retdata;
        

    }
    catch(err){
        console.log('getpooldata_err-->',err)
        return []
    }
}


    const GetApproveStatus = async (data, Addr, stake) => {
        try {
            let  ConnectContract = await contrat_connection(data == "Single" ? ERC721 : ERC1155 , Addr);
            let  contract_Method_Hash;
            if (stake) {
                contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .isApprovedForAll(accountAddress, config.StakeAddress)
                        .call()
            }
            else {
                contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .isApprovedForAll(accountAddress, config.TradeContract)
                        .call()
            }

            return contract_Method_Hash

        }
        catch (e) {
            console.log('GetApproveStatus_err-->',e)
            return 'error'
        }
    }
    const SetApproveStatus = async (data, Addr, stake) => {
        try {
            let  ConnectContract = await contrat_connection( data == "Single" ? ERC721 : ERC1155  , Addr);
            let  contract_Method_Hash;
            if (stake) {
                contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .setApprovalForAll(config.StakeAddress, true)
                        .send({
                            from: accountAddress
                        }).on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            else {
                contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .setApprovalForAll(config.TradeContract, true)
                        .send({
                            from: accountAddress
                        }).on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }

            let  receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

            let  need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;

        }
        catch (e) {
            console.log('SetApproveStatus_err-->',e)
            return false
        }
    }
    const get_receipt = async (HashValue) => {
        let  receipt = await web3s.eth.getTransactionReceipt(HashValue);
        if (receipt) {
            return receipt
        }
        else {
            get_receipt(HashValue)
        }
    }
    const approve_721_1155 = async (token_address, ...data) => {
        try {
            let  ConnectContract = await contrat_connection(Token, token_address)
            let  contract_Method_Hash = await
                ConnectContract
                    .methods
                    .approve(...data)
                    .send({ from: accountAddress })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            let  receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            let  need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log('approve_721_1155_err-->',e)
            return false
        }
    }
    const Token_Balance_Calculation = async (token_Address, data) => {

        try {
            let  ConnectContract = await contrat_connection(Token, token_Address)
            let  bidAMt = await ConnectContract.methods.balanceOf(data).call();

            return Number(web3s.utils.fromWei(String(bidAMt)))
        }
        catch (e) {
            console.log('Token_Balance_Calculation_err-->',e)
            return 0
        }
    }
    var buy_bid_price_calculation = (val, decimal) => {
        let toMid = val * 1000000
        let serfee = (toMid / 100000000) * (web3s?.utils.fromWei(String(buyerFees ? buyerFees : 1)) * 1000000)
        let totfee = serfee + toMid
        let tot2cont = web3s?.utils.toWei(String(Number(totfee / 1000000)).length > 18 ? String(Number(totfee / 1000000).toFixed(18)) : String(Number(totfee / 1000000)))
        let dec = decimal == 18 ? 18 : 18 - (decimal);
        let aprrove = ((tot2cont) / 10 ** dec)
        return (aprrove)
    }
   
    var price_calculation = (data, roy) => {
        try {
            let price = web3s?.utils.toWei(data);
            let service_from_val = ((price * (sellerFees)) / 1e20)
            let royal_from_val = ((price * (roy * 1e18)) / 1e20)
            let my_val = 0, my_val_royal = 0, getVal = 0;
            if (String(service_from_val).includes('.') && String(service_from_val).split('.').pop().length > 18)
                my_val = service_from_val.toFixed(18)
            else
                my_val = service_from_val

            if (String(royal_from_val).includes('.') && String(royal_from_val).split('.').pop().length > 18)
                my_val_royal = royal_from_val.toFixed(18)
            else
                my_val_royal = royal_from_val
            var whole_val = (((price)) - my_val) / 1e18
            if (String(whole_val).includes('.') && String(whole_val).split('.').pop().length > 18)
                getVal = whole_val.toFixed(18)
            else
                getVal = whole_val
            return getVal

        }
        catch (e) {
            console.log('price_calculation_err-->',e)
            return false
        }
    }
   
    const buy_721_1155 = async ( CoinName, ...data) => {
        try {
            let  ConnectContract = await contrat_connection(ERC1155, config.ERC1155)
            if (CoinName != "BNB" || CoinName != "ETH") {
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .buyNFT(...data)
                        .send({
                            from: accountAddress,
                        })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
           

            let  receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            let ids=[]
            let  route = String(receipt.logs[2].data)
            let  sliceee = route.slice(2)
            let  lengthuh = sliceee.length / 2
    
            let  TokenID = web3s.utils.hexToNumber("0x" + sliceee.slice(0, lengthuh))
    
                    ids.push(TokenID)
                    let  need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                        Tokenid: ids
                    }
                    return need_data
        }
        catch (e) {
            console.log('buy_721_1155_err-->',e)
            return {status : false}
        }

    }
    const allowance_721_1155 = async (token_Address, data) => {

        try {
            let  ConnectContract = await contrat_connection(Token, token_Address)
            let  contract_Method_Hash = await
                ConnectContract
                    .methods
                    .allowance(data, config.TradeContract)
                    .call()
            return contract_Method_Hash

        }

        catch (e) {
            console.log('allowance_721_1155_err-->',e)
            return false
        }

    }
    const accept_721_1155 = async (...data) => {
        try {
            if (web3 != null) {
                let ConnectContract = await contrat_connection(Market, config.TradeContract)
                let contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .acceptBId(...data)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                let  receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                let  need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                }
                return need_data
            }
        }
        catch (e) {
console.log('accept_721_1155_err-->',e)
            return false
        }

    }

    const GetOwner = async (data, Addr, Tokenaddr) => {
        try {
            let  ConnectContract = await contrat_connection( data == 'Single' ? ERC721 : ERC1155 , Addr)
            let  contract_Method_Hash = await
                ConnectContract
                    .methods
                    .ownerOf(Tokenaddr)
                    .call()
            return contract_Method_Hash

        }
        catch (e) {
            console.log('GetOwner_err-->',e)
            return 'error'
        }
    }

    const GetContractOwner = async (data, Addr) => {
        try {
            let  ConnectContract = await contrat_connection( ERC721 , Addr)
            let  contractowner = await
                ConnectContract
                    .methods
                    .owner()
                    .call()
            return contractowner

        }
        catch (e) {
            console.log('GetContractOwner_err-->',e)
            return 'error'
        }
    }

    const Current_NFT_Balance = async (ownerdet, data) => {

        try {
            let  currbalance;
            if ((data.ContractType === "721" || data.ContractType === 721)) {
                const ConnectContract = await contrat_connection(ERC721, data.ContractAddress)
                currbalance = await ConnectContract.methods.ownerOf(ownerdet.NFTId).call();
                if ((String(currbalance).toLowerCase()) === (String(ownerdet.NFTOwner).toLowerCase())) { return '1'; }
            }
            return String(currbalance);
        }
        catch (e) {
            console.log('Current_NFT_Balance_err-->',e)
            return 0
        }
    }

    const lazyminting_721_1155 = async (count,type,coin, Send, ...data) => {
        let  ConnectContract = "";
        try {

            ConnectContract = await contrat_connection( (type=="721" || type == 721) ?  ERC721 : ERC1155, (type=="721" || type == 721) ?  config.ERC721 : config.ERC1155)
            let  contract_Method_Hash
if(coin == "BNB" || coin == "ETH"){
    contract_Method_Hash = await
    ConnectContract
        .methods
        .lazyMint(...data)
        .send({
            from: accountAddress,
            value: Send
        })
        .on('transactionHash', (transactionHash) => {
            return transactionHash
        })
}else{
    contract_Method_Hash = await
                ConnectContract
                    .methods
                    .lazyMint(...data)
                    .send({
                        from: accountAddress
                    })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
}
             


            let  receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            let  ids=[]
            if(count != ""){
                for(let i=0 ;i<count;i++){
                    ids.push(web3s.utils.hexToNumber(Number(receipt.logs[i].topics[3]))) 
                }
                let  need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                    Tokenid: ids
                }
                return need_data
            }
            else{
                let  route = String(receipt.logs[0].data)
        let  sliceee = route.slice(2)
        let  lengthuh = sliceee.length / 2

        let  TokenID = web3s.utils.hexToNumber("0x" + sliceee.slice(0, lengthuh))

                ids.push(TokenID)
                let  need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                    Tokenid: ids
                }
                return need_data
            }
            
        }
        catch (err) {
            console.log('lazyminting_721_1155_err-->',err)
            return {status : false}
        }

    }

    const Revealnft = async (id) =>{
        try{
            let   ConnectContract = await contrat_connection(ERC721, config.ERC721)
            let  contract_Method_Hash = await
            ConnectContract
                .methods
                .reveal(id)
                .send({
                    from: accountAddress
                })
                .on('transactionHash', (transactionHash) => {
                    return transactionHash
                })
                let  receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
       
       if(receipt?.status){
        return {status : receipt?.status}
       }
            }
        catch(err){
            console.log('Revealnft_err-->',err)
            return {status : false,msg : 'Try again'}
        }
        
    }

    return {
        lazyminting_721_1155,
        Contract_Base_Validation,
        GetApproveStatus,
        SetApproveStatus,
        approve_721_1155,
        Token_Balance_Calculation,
        buy_bid_price_calculation,
        price_calculation,
        buy_721_1155,
        allowance_721_1155,
        accept_721_1155,
        GetOwner,
        GetContractOwner,
        Current_NFT_Balance,
        Getadminaddress,
        EnableStake,
        addpool,
        Getreward,
        Getdayreward,
        GetPoll,
        NFTWithdraw,
        Revealnft,
        getpooldata
    };




}
