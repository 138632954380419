import React, { useState,useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import coin from '../assets/images/coin.png'
import Storecard from '../components/storecard';

import store_line from '../assets/images/store_line.png'
import inner_line from '../assets/images/inner_line.png'
import {getCmsContent} from '../actions/axios/useaxios'
import HeaderOne from '../layout/HeaderOne';
export default function Privacy() {
  const [privacy,setprivacy]=useState()

  useEffect(() => {
    Privacy();
  }, [])
  // const getcms = async () => {

  //   let  resp = await getCmsContent();
  //   if (resp?.success) {
  //     setterms(resp?.data);
  //   }

  // }
  const Privacy = async () => {
    var resp = await getCmsContent({data:"privacypolicy"});
    console.log("privacypolicy", resp.data);
    if (resp?.status) setprivacy(resp?.data);
  };
  return (
    <div className='inner_pages'>
      <HeaderOne />
      <div className='cms_pages'>
        <div className='inner_sec'>

          <div className='container inner_container'>


            <div className='top_sec'>
              <div className='row'>
                <div className='text-center mb-4'>
                  <p className='page_title imfell_font'>Privacy Policy</p>
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
                </div>
                <div className='desc_sec'>
                </div>
              </div>

            </div>
          <div className='cms_content_sec'>
        
            <ul>
            {/* {term?.map((item)=>{
                return(
                  item.slug=="privacypolicy"&& */}
              <li>
                <p className='desc averia_font'  dangerouslySetInnerHTML={{__html:privacy?.answer}}></p>
              </li>
            {/* )})} */}
            </ul>
                
              
          </div>
           
           
          </div>


        </div>
      </div>


      <Footer />

    </div>
  )
}
