import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Footer from '../layout/footer';
import CharCard from '../components/charcard';
import inner_line from '../assets/images/inner_line.png'
import Mintingmodal from '../components/modal/mintingmodal';
import HeaderOne from '../layout/HeaderOne';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation , useNavigate } from 'react-router-dom';
import { Dropdetail } from '../actions/axios/nftaxios';
import Nodata from '../components/nodata';
import { toast } from 'react-toastify';
export default function Detaildrop() {
  const location = useLocation()
  const push= useNavigate()
  const [id,setId]=useState(location.state.id)
  const { payload, token } = useSelector(state => state.LoginReducer.User)
  const { AdminAddress ,Settings} = useSelector((state) => state.LoginReducer);
  const [Tokens, SetTokens] = useState({})
  const [TabName, SetTabName] = useState("sale");
  const [mintingModal, setMintingModal] = useState(false);
  const [buydetail, setBuydetail] = useState({});
  const [selectids,setSelectids]= useState([])
  useEffect(()=>{
    if(!Settings?.walletsection){
      push('/')
    }
  },[Settings])
  useEffect(() => {
    if (AdminAddress && payload?.WalletAddress && AdminAddress !== payload?.WalletAddress) {
        toast.error("Sorry Admin only allowed")
        push('/')
    }
  }, [AdminAddress,payload])


  const handleSelectAllChange = (check) => {
    if(check){
      setSelectids([...Tokens[TabName].list.map((val)=>val._id)])
    }
    else{
      setSelectids([])
    }
  };
  const handleCheckboxChange = (index,id,checked) => {
    if(checked){
      setSelectids([...selectids,id])
    }
    else{
      setSelectids(selectids.filter((e)=>e!=id))
    }
  };
useEffect(()=>{
  if (typeof Tokens[TabName] == 'undefined' && token) {
    Tokens[TabName] = { page: 1, list: [], loader: false };
    SetTokens(Tokens);
    getdropdata(1, TabName);
  }
},[id,TabName,token])

const getdropdata = async(page,Tab)=>{
  let SendDATA ={id : id , Tab : Tab ?? TabName,page : page ? page : (Tokens[TabName]?.page),limit : 12}
  let resp = await Dropdetail(token,SendDATA)
  if(resp?.status){
    SetTokens({
      ...Tokens, ...{
        [TabName]: {
          list: SendDATA.page == 1 ? resp?.datas.length > 0 ? resp?.datas?.map((val)=>val.nfts)  : [] : [...Tokens[TabName]?.list, ...resp?.datas?.map((val)=>val.nfts)],
          page: Tokens[TabName]?.page ,
          loadmore: resp?.datas.length > 0 ? true : false,
          count : resp?.count ?? 0
        }
      }
    })
  }
}

const onsale = async ()=>{
  if(selectids.length > 0){
    let SendDATA = {
      dropid : id,
      ids : selectids,
      from : "later"
    }
    let resp = await Dropdetail(token,SendDATA)
    if(resp?.status){
      toast.success(resp?.msg)
      getdropdata()
      delete Tokens.sale
    }
    else{
      toast.error(resp?.msg)
    }
  }
  else{
    toast.error('Please Select Atleast one Token')
  }
}

const loadmore = async ()=>{
  Tokens[TabName].page = Tokens[TabName].page + 1;
  SetTokens(Tokens);
  getdropdata(Tokens[TabName].page);
}

  return (
    <div className='inner_pages'>
      <HeaderOne />
      <div className='quest_pages drop_pages'>
        <div className='inner_sec'>

          <div className='container inner_container'>


            <div className='top_sec'>
              <div className='row'>
                <div className='text-center mb-4'>
                  <p className='page_title'>Drop {location?.state?.index+1}</p>
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
                </div>
                <div className='desc_sec'>

                  <div className='coin_sec'>
                    <p className='balance_coin'><span className='mx-2'>Qty : {Tokens[TabName]?.count}</span > <span className='mx-2'>Price : {location?.state?.price}{" "}{location?.state?.coin}</span></p>
                  </div>

                </div>
              </div>

            </div>
            <div className='tab_sec'>
              <Tabs
                defaultActiveKey="sale"
                id="uncontrolled-tab-example"
                className="tabs_design mb-3"
                onSelect={(e) => SetTabName(e)}
              >
                <Tab eventKey="sale" title="SALE">

                  <div className='row'>
                    {(Tokens[TabName]?.list &&  Tokens[TabName]?.list?.length > 0) ?  Tokens[TabName]?.list.map((e, i) => (
                      <div className='col-xl-3 col-md-6  mb-4'>
                        <CharCard
                          type={'sale'} product={e} />
                      </div>
                    ))
                    : <>
                    <Nodata/>

                  </>

                  }
                  </div>

                 { Tokens[TabName]?.loadmore && <div className='loadmore_btn_sec'>
                    <button className='btn loadmore_btn' alt="loadmore" onClick={loadmore} >Load More</button>
                  </div>}

                </Tab>
                <Tab eventKey="later" title="SALE LATER">
                 {Tokens[TabName]?.list?.length > 0 && <div className='select_all_sec'>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"  onChange={(e)=>{handleSelectAllChange(e.target.checked)}} />
                      <label class="form-check-label" for="flexCheckDefault">
                        Select All
                      </label>
                    </div>
                  </div>}
                  <div className='row'>
                    {(Tokens[TabName]?.list &&  Tokens[TabName]?.list?.length > 0) ?  Tokens[TabName]?.list.map((val, i) => (
                     
                      <div className='col-xl-3 col-md-6  mb-4'>
                         
                        <div className='sale_card_coll'>
                          <CharCard
                            type={'salelater'} product={val}  />
                              <div className='select_card_sec'>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" checked={selectids.includes(val?._id)}  onChange={(e) => {handleCheckboxChange(i,val?._id,e.target.checked)}} />
                            <p></p>
                          </div>
                        </div>
                        </div>
                      
                      </div>
                    ))
                    : <>
                    <Nodata/>

                  </>}

                  </div>

                  <div className='loadmore_btn_sec'>
                    <button className='btn loadmore_btn m-3' alt="loadmore" onClick={onsale} >Save</button>
{Tokens[TabName]?.loadmore &&                     <button className='btn loadmore_btn' alt="loadmore" onClick={loadmore} >Load More</button>
}                  </div>
                </Tab>

              </Tabs>
            </div>
          </div>


        </div>
      </div>


      <Footer />
      {buydetail && <Mintingmodal
        imgbuy={buydetail.char_img}
        show={mintingModal}
        onHide={() => setMintingModal(false)}
        buymodal
      />
      }
    </div>
  )
}
