import React, { useState, useEffect } from 'react';
import Footer from '../layout/footer';
import Header from '../layout/header';
import Marquee from "react-fast-marquee";
import banner_crown from '../assets/images/saveagedd.png';
import banner_crown1 from '../assets/images/new_img/mcrown.png';
import leftsec from '../assets/images/leftsecnewsat.webp';
import rightsec from '../assets/images/rightsecnewsat.webp';
import rightsec1 from '../assets/images/new_img/rightnewb.webp';
import leftsec1 from '../assets/images/new_img/leftnewb.webp';
import king_img from '../assets/images/new_img/newking.webp';
import grad_bg from '../assets/images/gradient.png';
import leftflag_sec from '../assets/images/leftflagnew.png';
import leftflag_sec1 from '../assets/images/flags-left2.png';
import rightflag_sec from '../assets/images/rightflagnew.png';
import rightflag_sec1 from '../assets/images/flags-right2.png';
import end_line from '../assets/images/end_line.png';
import characters_img from '../assets/images/characters.png';
import training_img from '../assets/images/training_img.png';
import faq_img from '../assets/images/faq_img.png';
import tower_full from '../assets/images/new_img/tower.jpg';
import gradsec_img from '../assets/images/new_img/gradsec.png';
import Mintingmodal from '../components/modal/mintingmodal';
import { getCmsContent, getFaq, getaboutuser, roadmaplist } from '../actions/axios/useaxios'
import bottomimg from '../assets/images/Towerssss.webp';
import Loader from '../layout/Loader';
import { useDispatch, useSelector } from 'react-redux';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
import config from '../components/config/config'

import 'swiper/css/navigation';


export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  const [mintingModal, setMintingModal] = useState(false);
  const [cms, setcms] = useState([])
  console.log("cmslists", cms);
  const [faq, setfaq] = useState()
  console.log("datssss", faq)
  const [load, setLoad] = useState(false)
  const [homecontent, setHomecontent] = useState([]);
  // const [homecontentmidd, setHomecontentmidd] = useState([]);
  const [Character, setCharacter] = useState([]);
  const [training, setTraining] = useState([]);
  const [roadmap, setRoadmap] = useState([])
  const [aboutuser, setAboutuser] = useState([])
  const [AboutUs, setAboutUs] = useState([])
  const [Cards, setCards] = useState([])
  console.log("caterdss", Cards);
  const [towerimage, setTowerimage] = useState([])
  const [Logo, setLogo] = useState([])
  const { Settings } = useSelector((state) => state?.LoginReducer)



  const [charimg, setCharimg] = useState([
    {
      char_img: require('../assets/images/char.png')
    },
    {
      char_img: require('../assets/images/char1.png')
    },
    {
      char_img: require('../assets/images/char2.png')
    },
    {
      char_img: require('../assets/images/char3.png')
    },
    {
      char_img: require('../assets/images/char4.png')
    },
    {
      char_img: require('../assets/images/char5.png')
    },
    {
      char_img: require('../assets/images/char6.png')
    },
    {
      char_img: require('../assets/images/char7.png')
    },
    {
      char_img: require('../assets/images/char8.png')
    },
    {
      char_img: require('../assets/images/char9.png')
    },


  ])
  const [teamcards, setTeamcards] = useState([
    {
      team_img: require('../assets/images/teamimg.png')
    },
    {
      team_img: require('../assets/images/teamimg1.png')
    },
    {
      team_img: require('../assets/images/teamimg2.png')
    },
    {
      team_img: require('../assets/images/teamimg3.png')
    },


  ])


  useEffect(() => {
    setLoad(true);
    getcms();
    Getandselldata()
    Aboutuser()
    AbouUs()
    TowerImage()
    SavageLogo()

    if (Settings?.faqsection) {
      getfaqlist()
    }
    if (Settings?.charactersection) {
      character()
      CardsImages()
    }
    if (Settings?.roadmapsection) {
      roadMap()
    }
    if (Settings?.trainingsection) {
      Training()
    }

  }, [Settings])

  const getcms = async () => {
    let resp = await getCmsContent();
    console.log("reponses", resp);
    if (resp?.success) {
      setcms(resp?.data);
    }
  }

  const getfaqlist = async () => {
    let resp = await getFaq();
    if (resp?.success) {
      setfaq(resp?.data);
    }
  }

  const Getandselldata = async () => {
    var resp = await getCmsContent({ data: "homepage_top" });
    console.log("cmslistdfgfd", resp?.data)
    if (resp?.status) setHomecontent(resp.data);
  };


  const character = async () => {
    var resp = await getCmsContent({ data: "character" });
    console.log("character", resp.data);
    if (resp?.status) setCharacter(resp?.data);
  };

  const Training = async () => {
    var resp = await getCmsContent({ data: "training" });
    console.log("Training", resp.data);
    if (resp?.status) setTraining(resp?.data);
  };

  const AbouUs = async () => {
    var resp = await getCmsContent({ data: "aboutus" });
    console.log("aboutus", resp.data);
    if (resp?.status) setAboutUs(resp?.data);
  };

  const CardsImages = async () => {
    var resp = await getCmsContent({ data: "cards" });
    console.log("cardss", resp.data);
    if (resp?.status) setCards(resp?.data);
  };

  const TowerImage = async () => {
    var resp = await getCmsContent({ data: "footertower" });
    console.log("footertower", resp.data);
    if (resp?.status) setTowerimage(resp?.data);
  };

  const SavageLogo = async () => {
    var resp = await getCmsContent({ data: "savagelogo" });
    console.log("SavageLogo", resp.data);
    if (resp?.status) setLogo(resp?.data);
  };

  const roadMap = async () => {
    var resp = await roadmaplist();
    console.log("roadMap", resp.data);
    if (resp?.status) setRoadmap(resp?.data);
  }

  const Aboutuser = async () => {
    var resp = await getaboutuser();
    console.log("Aboutuser", resp.data);
    if (resp?.status) setAboutuser(resp?.data);
  }


  return (
    <>
      {load &&
        <Loader />
      }
      <div className='homepage'>

        <Header />
        <div className='homepage_inner'>

          <div className='banner_home'>
            <div className='container custom_container'>
              <div className='row'>
                {Settings?.logosection && <div className='banner_sec'>

                  <div className='anim_fog'>

                  </div>
                  <img src={grad_bg} alt='grad' className='grad_bg img-fluid' />
                  <img src={leftsec} className="left_img  d-none d-md-block" alt='rabbit' />
                  <img src={leftsec1} className="left_img d-block d-md-none" alt='rabbit' />
                  <img src={rightsec} className="right_img d-none d-md-block" alt='rightsec' />
                  <img src={rightsec1} className="right_img d-block d-md-none" alt='rightsec' />
                  <img src={leftflag_sec} className="leftflag_sec" alt='leftflag_sec' />
                  <img src={leftflag_sec1} className="leftflag_sec1" alt='leftflag_sec1' />
                  <img src={rightflag_sec} className="rightflag_sec" alt='rightflag_sec' />
                  <img src={rightflag_sec1} className="rightflag_sec1" alt='rightflag_sec1' />
                  <img src={bottomimg} className="trained" alt='leftflag_sec' />


                  <div className={scroll ? "row first_row scroll" : "row first_row"}
                  >
                    {/* <img src={banner_crown} alt='crown' className='img-fluid banner_crown' width={100} /> */}
                    <img src={Logo?.Image ? `${config.IMG_URL}/cmsimages/${Logo?.Image}` : banner_crown} alt='crown' className='img-fluid banner_crown' width={100} />

                  </div>
                </div>}
                <div className='back_grad'></div>
              </div>
            </div>
          </div>
         { Settings?.kingimagesection && <div className='second_sec row'>
            <div>
              {/* <img src={king_img} alt='king_img' className='king_img img-fluid' /> */}
              <img src={homecontent?.Image ? `${config.IMG_URL}/cmsimages/${homecontent?.Image}` : king_img} alt='king_img' className='king_img img-fluid' />

            </div>
            <div>
              <img src={gradsec_img} alt='king_img' className='gradsec_img img-fluid' />
            </div>
          </div>}
          {Settings?.kingdomsection &&
          <> 
           <div className='third_desc_sec'>

            <div className='container inner_container custody_container'>
              <div className='row'>

                <div className='content_sec'>

                  <p className='averia_font'>
                    <p><div dangerouslySetInnerHTML={{ __html: homecontent?.answer }}></div>

                    </p>
                  </p>


                  <img src={end_line} alt='end_line' className='end_line mt-3 mt-md-5' width={200} />
                </div>

              </div>
            </div>

          </div>
          <div className='patternsec'>
            <div class="story-pattern"></div>
            <div className='container inner_container custody_container'>
              <div className='row'>
                <div className='card_sec card_sec_glow'>
                  <div className='glow_sec'>
                    <div className='img_glow'> </div>
                    {/* <img src={faq_img} className='faq_img' width={1020} height={765} alt="faq_img" /> */}
                    <img src={AboutUs?.Image ? `${config.IMG_URL}/cmsimages/${AboutUs?.Image}` : faq_img} className='faq_img' width={1020} height={765} alt="faq_img" />

                  </div>
                  {/* {cms?.map((item) => {
                    return (
                      item.slug == "aboutus" && */}
                  <div className='design_card'>
                    <p className='pt-5 pt-sm-3'></p>
                    <p className='averia_font patternsec_p' dangerouslySetInnerHTML={{ __html: AboutUs?.answer }}></p>
                  </div>
                  {/* )
                  })} */}

                </div>
              </div>
            </div>
          </div>
          </>
          }

          
         { Settings?.charactersection && <div className='charactor_sec'>
            <div className='container inner_container custody_container'>
              <div className='row'>
                <div className='content_sec mt-5'>
                  <div className='img_sec'>
                    {/* <img src={characters_img} alt='character_img' className='char_img1' width={500} /> */}
                    <img src={Character?.Image ? `${config.IMG_URL}/cmsimages/${Character?.Image}` : characters_img} alt='character_img' className='char_img1' width={500} />

                  </div>
                  <p className='sec_title mt-5 imfell_font mb-4 kr_fontsize'>{Character?.question?.toUpperCase()}</p>
                  {/* {cms?.map((item) => {
                    return (
                      item.slug == "character" && */}
                  <p className='sec_desc averia_font' dangerouslySetInnerHTML={{ __html: Character?.answer }}></p>)
                  {/* // })} */}
                  <img src={end_line} alt='end_line' className='end_line mt-3' width={200} />
                </div>


              </div>

            </div>
            <div className='marquee_sec'>
              <Marquee speed={100} direction='left'>
                <div className='scrollanimchar'>
                  {Cards?.Image?.map((e) => (
                    <div>
                      {/* <img src={e.char_img} alt='chr_img' className='chr_img' /> */}
                      <img src={`${config.IMG_URL}/cmsimages/${e}`} alt='chr_img' className='chr_img' />

                    </div>
                  ))}
                </div>
              </Marquee>
              <Marquee speed={100} direction='right'>
                <div className='scrollanimchar'>
                  {Cards?.Image?.map((e) => (
                    // <img src={e.char_img} alt='chr_img' className='chr_img' />
                    <img src={`${config.IMG_URL}/cmsimages/${e}`} alt='chr_img' className='chr_img' />

                  ))}
                </div>
              </Marquee>



            </div>

            {/* mobile owl carousel section */}
            <div className='container-fluid my-0 my-md-5 swiper_contents'>


              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}

                modules={[Pagination, Autoplay]}
                className="mySwiper"
                breakpoints={{
                  320: {
                    slidesPerView: 1.5,
                  },
                  576: {
                    slidesPerView: 2,
                  },
                }}
              >
                {Cards?.Image?.map((e) => (
                  <SwiperSlide>
                    {/* <img src={e.char_img} alt='chr_img' className='swiper_img' /> */}
                    <img src={`${config.IMG_URL}/cmsimages/${e}`} alt='chr_img' className='swiper_img' />

                  </SwiperSlide>
                ))}



              </Swiper>


            </div>
            {/*end of mobile owl carousel section  */}

          </div>}

         {  Settings?.trainingsection && <div className='training_sec'>
            <div className='container-fluid custody_container'>
              <div className='row first_row'>
                <div className='col-lg-6 leftsec text-center'>
                  {/* <img src={training_img} alt='training_img' className='training_img img-fluid' /> */}
                  <img src={training?.Image ? `${config.IMG_URL}/cmsimages/${training?.Image}` : training_img} alt='training_img' className='training_img img-fluid' />


                </div>
                <div className='col-lg-6 rightsec'>
                  <p className='sec_title imfell_font kr_fontsize'>{training?.question?.toUpperCase()}</p>

                  <p className='sec_desc averia_font'>
                    <p><div dangerouslySetInnerHTML={{ __html: training?.answer }}></div>

                    </p>
                  </p>
                </div>

              </div>
              <img src={end_line} alt='end_line' className='end_line' width={200} />

            </div>                </div>}

          {/* <div className='mb-5'>
            {cms?.map((item) => {
              return (
                item.slug == "tower" &&
                <div className='container custody_container margin_top_minu_daf'>
                  <div className='relativing_abs'>
                    <div className='savagesection'>
                      <p className='sec_title imfell_font kr_fontsize'>SAVAGE <span className='kr_fontsizef'>T</span>OWER</p>
                      <p className='sec_desc averia_font'  >
                        <div dangerouslySetInnerHTML={{ __html: item?.answer }}></div>
                      </p>
                    </div>
                    <div className='text-center'>
                      <img src={end_line} alt='end_line' className='end_line' width={200} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div> */}
          {Settings?.roadmapsection && <div className='roadmap_sec'>
            <div className='container inner_container custody_container'>
              <div className='row'>

                <div className='top_sec'>
                  <p className='title imfell_font kr_fontsize'>OUR <span className='kr_fontsizef'>R</span>OADMAP</p>
                </div>
              </div>
              <div className='full_map'>
                <div className='mapsec top_sec'>
                  <div className='row first_row'>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-5'>
                      <p className='sec_title imfell_font'>{roadmap?.find((val) => val?.step == 1)?.question}</p>
                      <p className='sec_desc averia_font'><div dangerouslySetInnerHTML={{ __html: roadmap?.find((val) => val.step == 1)?.answer }}></div></p>
                    </div>

                    <div className='col-lg-5'>

                    </div>
                  </div>
                  <div className='row second_row'>
                    <div className='col-lg-5'>

                    </div>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-5'>

                      <p className='sec_title imfell_font'>{roadmap?.find((val) => val?.step == 2)?.question}</p>
                      <p className='sec_desc averia_font'><div dangerouslySetInnerHTML={{ __html: roadmap?.find((val) => val.step == 2)?.answer }}></div></p>

                    </div>
                  </div>
                  <div className='row third_row'>
                    <div className='col-lg-5'>
                      <p className='sec_title imfell_font'>{roadmap?.find((val) => val?.step == 3)?.question}</p>
                      <p className='sec_desc averia_font'><div dangerouslySetInnerHTML={{ __html: roadmap?.find((val) => val.step == 3)?.answer }}></div></p>
                    </div>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-5'>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
          {/* <div className='team_sec'>
            <div className='container inner_container custody_container'>
              <div className='row title_top'>
                <p className='sec_title imfell_font kr_fontsize'>OUR <span className='kr_fontsizef'>T</span>EAM</p>
              </div>

              <div className='row card_sec'>
                {aboutuser.map((e) => (
                  <div className='col-lg-3 col-md-6 col-sm-6 mb-4  '>
                    <div className='team_card'>
                      <div className='team_card_bg'></div>
                      <img src={`${config.IMG_URL}/About/${e.img}`} alt='team_img' className='team_img' />

                    </div>
                  </div>
                ))}
              </div>
              <div className='end_line_sec'>
                <img src={end_line} alt='end_line' className='end_line' width={200} />
              </div>
            </div>
          </div> */}

        </div>
        <div className='tower_sec'>
          <div className='bottom_sec'>
            <div className='content_sec sticking'>
             {Settings?.faqsection &&  <div className='container custody_container margin_top_minu_daf'>
                <div className='relativing_abs'>
                  <div className='leftside leftside_fasqs'>
                    <div className='faq_sec'>



                      <div className='left_sec'>
                        <p className='sec_title imfell_font'>Frequently asked questions</p>

                      </div>
                      <div className='mt-4'>
                        <div class="accordion" id="accordionExample">
                          {faq?.map((item, index) => {
                            item["ind"] = index
                            return (

                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                  <button class="accordion-button averia_font" type="button" data-bs-toggle="collapse" data-bs-target={`#${index}`} aria-expanded="false" aria-controls="collapseOne">
                                    {item?.question}
                                  </button>
                                </h2>
                                <div id={index} class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                  <div class="accordion-body">
                                    <strong><p className='averia_font averia_font_small' dangerouslySetInnerHTML={{ __html: item?.answer }}></p></strong>
                                  </div>
                                </div>
                              </div>)
                          })}

                        </div>
                      </div>



                    </div>

                  </div>
                </div>
              </div>
}



            </div>



            {/* <img src={tower_full} alt='tower' className='img-fluid tower_full' /> */}
            <img src={towerimage?.Image ? `${config.IMG_URL}/cmsimages/${towerimage?.Image}` : tower_full} alt='tower' className='img-fluid tower_full' />

          </div>






        </div>
        <div className='footer_sec'>
          <Footer />
        </div>
        <Mintingmodal
          show={mintingModal}
          onHide={() => setMintingModal(false)}
        />
      </div>
    </>

  )
}
