import React from 'react';
import { Link,useNavigate } from "react-router-dom"
import clk from '../assets/images/clk.png'
import checkmark from '../assets/images/checkmark.png';
import Countdown from 'react-countdown';
import imgs from '../assets/images/quest_pic.png'
import { useSelector } from 'react-redux';
import { ToastContainer,toast } from 'react-toastify';
export default function Questcard({
  quest_img,
  desc,
  heading,
  category,
  days,
  nftscount,
  requiretype,
  poolid,
  id,
  tokens,
  type,
  endtime,
  stakeddata,
  attribute,
  hash,
  pooldata
}) {
  const navigate= useNavigate()
  const { accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const onclick= ()=>{
    if(accountAddress){
     navigate('/questdetail',{state:{ data: {
      quest_img,
      desc,
      heading,
      requiretype,
      category,
      days,
      poolid,
      id,
      nftscount,
      tokens,
      endtime,
      stakeddata,
      type,
      attribute,
      hash,
      pooldata
    }}})
    }
    else{
      toast.error("Please connect Wallet")
    }
  }

  return (
    <div>
      <div className='card quest_card_style'>
        <div className='row first_row'>
          <div className='col-md-4 col-lg-4 col-xl-5  leftside'>
            <div className='img_sec'>
              <img src={quest_img ? quest_img : imgs} alt='quest_img' className='quest_pic img-fluid' />
            </div>
          </div>
          <div className='col-md-8 col-lg-8 col-xl-7   rightside'>
            {type == 'active' && <p className='time_duration'><Countdown date={new Date(endtime)} /></p>}
            <p className='title_card'>{heading}</p>
            <p className='catg'>Qualification</p>
            <p className='count'><img src={clk} className='img-fluid' alt='clock' width={15} /> {days} Days</p>
            <p className='count'><img src={checkmark} className='img-fluid' alt='clock' width={15} /> {nftscount} Savage NFTs</p>
            <p className='token_label'>Earn {tokens} Savage Tokens</p>
          </div>
        </div>
        <div className='bottom_link'>
          {type == 'completed' ?
            <div  className='quest' onClick={()=>onclick()}>QUEST COMPLETED</div>
             :
            <div className='quest' onClick={()=>onclick()}>VIEW QUEST</div>
          }
        </div>



      </div>
    </div>
  )
}
