
import config from '../../components/config/config'
import {axiosFunc, AppenData,EncryptData} from '../common'


var token ;

export const GetNftCookieToken = () => {
    token = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
      }, '');
}

export const Token_List_Func    =   async   (data)  =>  {
    try{
        let  senddata    =   {
            method  :   'post',
            url     :   `${config.BACK_URL}/nft/allexplore`,
            data  :   {data : EncryptData(data)},
            headers :{
                "content-type" : 'application/json'
            }  
            }
        let Resp    =   await axiosFunc(senddata)
    
        return Resp
    }catch(e){
        console.log('Token_List_Func_err-->',e)
    }
   
}


export const Token_MyList_Func    =   async   (data)  =>  {
    try{
        var senddata    =   {
            method  :   'GET',
            url     :   `${config.BACK_URL}/nft/myItemList`,
            params  :   {data : EncryptData(data)},
            headers :{
                "content-type" : 'application/json'
            }  
            }
        let Resp    =   await axiosFunc(senddata)
    
        return Resp
    }catch(e){
        console.log('Token_MyList_Func_err-->',)
    }

   
}

export const buyipfsandupdate=async(data)=>{
    try{
        let  senddata    =   {
            method  :   'post',
            url     :   `${config.BACK_URL}/nft/buyipfs`,
            data    :   {data : EncryptData(data)},
            headers :{
                "content-type" :'application/json'
            }  
            
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp
    }catch(e){
        console.log('buyipfsandupdate_er-->',e)
    }
   
}

export const Buymint =   async(data) =>  {
    try{
        let  senddata    =   {
            method  :   'post',
            url     :   `${config.BACK_URL}/nft/Buymint`,
            data    :   {data : EncryptData(data)},
            'headers': {
                'Authorization': token,
                "content-type" : 'application/json'
              }
            }
        let Resp    =   await axiosFunc(senddata)
        return Resp
    }catch(e){
        console.log('Buymint_err-->',e)
    }
   
}

export const Dropdetail = async (tokens,data)=>{
    try{
        let  senddata    =   {
            method  :   'get',
            url     :   `${config.BACK_URL}/nft/dropdetail`,
            'headers': {
                'Authorization': tokens,
                "content-type" :'application/json'
              },
              params : data ?? {}
            }
        let Resp    =   await axiosFunc(senddata)
        return Resp
    }catch(e){
        console.log('Dropdetail_err-->',e)
    }
   
}
export const Enablestake = async(data) =>{
    try{
        let  senddata  =  {
            method  :   'POST',
            url     :   `${config.BACK_URL}/nft/EnableStake`,
            data    :   {data : EncryptData(data)},
            headers :{
                "content-type" : 'application/json'
            }
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp
    }catch(e){
        console.log('Enablestake_err-->',e)
    }
    
}

export const Revealnft = async (data)=>{
try{
    let senddata={
        method : "post",
        url : `${config.BACK_URL}/nft/Revealnft`,
        data : {data : EncryptData(data)},
        headers :{
            "content-type" :'application/json'
        }
    }
    let resp = await axiosFunc(senddata)
    return resp
}catch(e){
    console.log('Revealnft_err-->',e)
}
   
}

export const getstaked = async (data)=>{
    try{
        let senddata={
            method : "get",
            url  : `${config.BACK_URL}/nft/getactive`,
            params : {data : EncryptData(data)},
            headers :{
                "content-type" : 'application/json'
            }
        }
        let resp = await axiosFunc(senddata)
        return resp
    }catch(e){
        console.log('getstaked_err-->',)
    }
   
}
export const gettype = async ()=>{
    try{
        let senddata={
            method : "get",
            url  : `${config.BACK_URL}/nft/gettypes`,
           
        }
        let resp = await axiosFunc(senddata)
        return resp
    }catch(e){
        console.log('gettype_err-->',)
    }
    
}

export const addpools = async (data)=>{
    try{
        let Data = await AppenData(data)
        let senddata={
            method : "post",
            url  : `${config.ADMIN_URL}/addeditdelete`,
            data : Data[0],
            'headers' :{
                "content-type" : "multipart/form-data"
            }
           
        }
        let resp = await axiosFunc(senddata)
        return resp
    }
    catch(er){
        console.log("addpools_err",er);
    }
}