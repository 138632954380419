import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../src/assets/css/styles.css';
import Home from "../src/pages/home";
import Quest from "../src/pages/quest";
import Minting from "../src/pages/Minting";
import QuestDetail from './pages/questdetail';
import Myarmory from "../src/pages/myarmory";
import Store from "./pages/store";
import Privacy from "../src/pages/privacy";
import Terms from "../src/pages/terms";
import Detaildrop from '../src/pages/dropdetail'
import { ToastContainer, toast } from 'react-toastify';
import Loader from './layout/Loader';
// import Header from './layout/header';



function App() {
  // console.log=()=>{}
  // console.warn=()=>{}
  // console.error=()=>{}
  // console.info=()=>{}
  return (

<>

    <BrowserRouter basename='/'>
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/quest" element={<Quest />}/>
        <Route exact path="/minting" element={<Minting />}/>
        <Route exact path="/questdetail" element={<QuestDetail />}/>
        <Route exact path="/questdetailactive" element={<QuestDetail />}/>
        <Route exact path="/questdetailcompleted" element={<QuestDetail />}/>
        <Route exact path="/armory" element={<Myarmory />}/>
        <Route exact path="/store" element={<Store />}/>
        <Route exact path="/privacy" element={<Privacy />}/>
        <Route exact path="/terms" element={<Terms />}/>
        <Route exact path="/dropdetail" element={<Detaildrop />}/>
      </Routes>
    </BrowserRouter>
    <ToastContainer
				position="top-right"
				autoClose={1000}
				hideProgressBar={false}
				rtl={true}
				closeOnClick={true}
        closeButton={true}
				style={
					{zIndex:"999999",
					"width":"400px",
					"wordBreak":"break-word",
					"text-align":"center",
					"fontWeight":"500",
					marginTop:"70px"
				}} 
			/> 
  
    </>



  );
}

export default App;
