import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import inner_line from '../../assets/images/inner_line.png'

import config from '../config/config'
import { toast } from 'react-toastify';
import useContractProviderHook from '../../actions/contractProviderHook'
import { useSelector, useDispatch } from "react-redux";
import { Enablestake, Revealnft } from '../../actions/axios/nftaxios';


export default function Revealmodal(props) {
  const item = props.product
  const ContractCall = useContractProviderHook()
  const { isStake } = useSelector((state) => state.LoginReducer);
  const dispatch = useDispatch()

  const Reveal = async () => {

    let id = toast.loading("Reveal on process")

    let  hash = await ContractCall.Revealnft(item.NFTId)
    if(hash?.status){
      let  resp = await Revealnft({ NFTId: item.NFTId })
      if (resp.status) {
        toast.update(id, { render: resp.msg, isLoading: false, autoClose: 1000, type: "success" })
        dispatch({
          type: "Stake_Section",
          Stake_Section: {
            isStake: !isStake
          }
        })
        props.onHide()
      } else {
        toast.update(id, { render: resp.msg, isLoading: false, autoClose: 1000, type: "error" })
      }
    }
    else{
      toast.update(id, { render: hash.msg, isLoading: false, autoClose: 1000, type: "error" })
    }

   
  }
  return (
    <>


      <div >
        <Modal  {...props} centered size="md" className='revealmodal'>
          <div className='bg_pattern'></div>
          <div className='close_btn'>
            <p className='close_text' onClick={props.onHide}>X</p>
          </div>
          <Modal.Header >
            <Modal.Title><p>Do you Want to reveal this NFT?</p>
              <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='btn_sec'>

              <Button className='modal_btn' variant="secondary" onClick={() => Reveal()}>
                YES
              </Button>
              <Button className='modal_btn' variant="primary" onClick={props.onHide}>
                NO
              </Button>
            </div></Modal.Body>

        </Modal>
      </div>



    </>


  )
}
